import {
    Autocomplete,
    Box,
    Button,
    CircularProgress,
    Grid,
    Input,
    InputLabel,
    LinearProgress,
    TextField,
} from '@mui/material';
import React, { useContext, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSupportedLanguages } from '../../components/layout/hooks/useSupportedLanguages';
import { UserContext } from '../../context';
import TreeView from '../../components/treeview';
import TreeSearch from '../../components/treeview/TreeSearch';
import SaveIcon from '@mui/icons-material/Save';
import { CreateAdHocInviteForm } from '../../types/invite';
import AdHocManagersFields from './AdHocManagersFields';
import type { IAutocomplete } from '../../types';
import { InputContainer, CornerStoneData, Error } from '../../styled';
import { defaultValues } from '../../constants';
import { handleRequiredInput, handleEmptyInputs } from '../../helpers';
import { useAdHocInvite } from './hooks/useAdHocInvite';
import { useChosenBusinessSegment } from './hooks/useChosenBusinessSegment';
import { useLocationSearch } from './hooks/useLocationSearch';

const AdHocElevateOffline = () => {
    const { state } = useContext(UserContext);
    const { t } = useTranslation();
    const [searchQuery, setSearchQuery] = useState('');
    const [selectedItem, setSelectedItem] = useState<any>();
    const [lindeManagerRequired, setLindeManagerRequired] = useState(false);
    const [managersMandatoryOnSubmit, setManagersMandatoryOnSubmit] = useState(false);
    const [formValues, setFormValues] = useState<CreateAdHocInviteForm>(defaultValues);
    const { createInvite, isCreatingInvite: isSubmitLoading } = useAdHocInvite();
    const { languageOptions, isLoadingLanguageOptions: languageLoading } = useSupportedLanguages();
    const {
        countryCodes,
        businessRelationships,
        orgUnits,
        externalPositions,
        cornerstoneLabels,
        userSupportInfo,
        supportInfo,
        mandatoryFields,
        isLoading,
    } = useChosenBusinessSegment({ type: 'offline' });

    const {
        searchLocations,
        isLocationLoading: locationLoading,
        searchResults,
    } = useLocationSearch();

    const handleSubmit = (event: any) => {
        if (!handleEmptyInputs(mandatoryFields, formValues)) {
            event.preventDefault();
            createInvite(formValues, 'offline');
        } else {
            handleRequiredInput(
                event,
                mandatoryFields,
                formValues,
                setManagersMandatoryOnSubmit,
                setLindeManagerRequired,
                state,
            );
        }
    };

    const handleInputChange = (event: any) => {
        let errorMsg: any = document.querySelectorAll('.errorMsg');
        errorMsg.forEach((e: any) => {
            e.style.display = 'none';
            setManagersMandatoryOnSubmit(false);
        });
        const { name, value } = event.target;
        setFormValues({
            ...formValues,
            [name]: value,
        });
    };

    const handleAutocompleteChange = (value: string | IAutocomplete | null, name: string) => {
        let errorMsg: any = document.querySelectorAll('.errorMsg');
        errorMsg.forEach((e: any) => {
            e.style.display = 'none';
            setManagersMandatoryOnSubmit(false);
        });

        setFormValues({
            ...formValues,
            [name]: value || '',
        });
    };

    useEffect(() => {
        if (selectedItem && selectedItem.data) {
            setFormValues({
                ...formValues,
                orgUnitExternalId: selectedItem.data.externalId,
                orgUnitParentId: selectedItem.data.parentId,
            });
        } else if (selectedItem && !selectedItem.data) {
            setFormValues({
                ...formValues,
                orgUnitExternalId: selectedItem.externalId,
                orgUnitParentId: selectedItem.parentId,
            });
        }
    }, [selectedItem]);

    useEffect(() => {
        if (!state.user || !state.chosenBusinessSegment) return;

        if (state.chosenBusinessSegment?.defaultLanguage) {
            setFormValues({
                ...formValues,
                language: state.chosenBusinessSegment.defaultLanguage,
            });
        }
    }, [state.chosenBusinessSegment]);

    return (
        <>
            {isLoading ? (
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '70vh',
                    }}
                >
                    <CircularProgress />
                </div>
            ) : (
                <Box sx={{ width: 'calc(100vh-30px)', margin: '20px' }}>
                    <Grid container columnSpacing={6} sx={{ marginTop: '15px' }}>
                        <Grid item xs={6} sx={{ position: 'relative' }}>
                            <InputContainer>
                                <InputLabel htmlFor="country-code">
                                    {t('label.countryCode')}
                                    {mandatoryFields && mandatoryFields.CountryCode && (
                                        <span style={{ color: 'red' }}> *</span>
                                    )}
                                    <Error className="errorMsg" id="CountryCodeError">
                                        {t('errorMessage.RequiredField')}
                                    </Error>
                                </InputLabel>
                                <Autocomplete
                                    disablePortal
                                    id="combo-box-demo"
                                    options={countryCodes}
                                    sx={{ width: '100%' }}
                                    renderInput={(params) => (
                                        <TextField {...params} variant="standard" />
                                    )}
                                    onChange={(_, value) =>
                                        handleAutocompleteChange(value?.id ?? null, 'countryCode')
                                    }
                                />
                            </InputContainer>
                        </Grid>

                        <Grid item xs={6} sx={{ position: 'relative' }}>
                            <InputContainer>
                                <InputLabel htmlFor="business-relationship">
                                    {t('label.businessRelationship')}
                                    {mandatoryFields && mandatoryFields.BusinessRelationship && (
                                        <span style={{ color: 'red' }}> *</span>
                                    )}
                                    <Error className="errorMsg" id="BusinessRelationshipError">
                                        {t('errorMessage.RequiredField')}
                                    </Error>
                                </InputLabel>

                                <Autocomplete
                                    disablePortal
                                    id="combo-box-demo"
                                    options={businessRelationships}
                                    sx={{ width: '100%' }}
                                    renderInput={(params) => (
                                        <TextField {...params} variant="standard" />
                                    )}
                                    onChange={(_, value) =>
                                        handleAutocompleteChange(
                                            value?.externalId ?? null,
                                            'businessRelationshipExternalId',
                                        )
                                    }
                                />
                            </InputContainer>
                        </Grid>
                    </Grid>

                    <Grid container columnSpacing={6} sx={{ marginTop: '15px' }}>
                        <Grid item xs={6} sx={{ marginBottom: 'px' }}>
                            <InputContainer>
                                <InputLabel htmlFor="user-name">
                                    {t('formField.firstName')}
                                    {mandatoryFields && mandatoryFields.FirstName && (
                                        <span style={{ color: 'red' }}> *</span>
                                    )}
                                    <Error className="errorMsg" id="FirstNameError">
                                        {t('errorMessage.RequiredField')}
                                    </Error>
                                </InputLabel>
                                <Input
                                    fullWidth
                                    id="user-name"
                                    name="firstName"
                                    onChange={handleInputChange}
                                />
                            </InputContainer>
                        </Grid>

                        <Grid item xs={6}>
                            <InputContainer>
                                <InputLabel htmlFor="user-last-name">
                                    {t('formField.lastName')}
                                    {mandatoryFields && mandatoryFields.LastName && (
                                        <span style={{ color: 'red' }}> *</span>
                                    )}
                                    <Error className="errorMsg" id="LastNameError">
                                        {t('errorMessage.RequiredField')}
                                    </Error>
                                </InputLabel>
                                <Input
                                    fullWidth
                                    id="user-last-name"
                                    name="lastName"
                                    onChange={handleInputChange}
                                />
                            </InputContainer>
                        </Grid>
                    </Grid>

                    <Grid container columnSpacing={6} sx={{ marginTop: '15px' }}>
                        <Grid item xs={6} sx={{ marginBottom: 'px' }}>
                            <InputContainer>
                                <InputLabel htmlFor="user-email">
                                    {t('formField.email')}
                                    <Error className="errorMsg" id="EmailError">
                                        {t('errorMessage.RequiredField')}
                                    </Error>
                                    <Error className="errorMsg" id="EmailInvalid">
                                        {t('errorMessage.InvalidInput')}
                                    </Error>
                                </InputLabel>
                                <Input
                                    fullWidth
                                    id="user-email"
                                    name="email"
                                    onChange={handleInputChange}
                                />
                            </InputContainer>
                        </Grid>

                        <Grid item xs={6}>
                            <InputContainer>
                                <InputLabel htmlFor="user-phoneNbr">
                                    {t('label.phoneNumber')}
                                    <Error className="errorMsg" id="PhoneError">
                                        {t('errorMessage.RequiredField')}
                                    </Error>
                                    <Error className="errorMsg" id="PhoneInvalid">
                                        {t('errorMessage.InvalidInput')}
                                    </Error>
                                </InputLabel>
                                <Input
                                    fullWidth
                                    id="user-phoneNbr"
                                    name="phoneNbr"
                                    onChange={handleInputChange}
                                />
                            </InputContainer>
                        </Grid>
                    </Grid>

                    <Grid container columnSpacing={6} sx={{ marginTop: '15px' }}>
                        <Grid item xs={6} sx={{ marginBottom: 'px', position: 'relative' }}>
                            <InputContainer>
                                <InputLabel htmlFor="external-position">
                                    {t('label.externalPosition')}
                                    {mandatoryFields &&
                                        mandatoryFields.ExternalPositionExternalId && (
                                            <span style={{ color: 'red' }}> *</span>
                                        )}
                                    <Error className="errorMsg" id="ExternalPositionError">
                                        {t('errorMessage.RequiredField')}
                                    </Error>
                                </InputLabel>
                                <Autocomplete
                                    disablePortal
                                    id="external-position"
                                    options={externalPositions}
                                    sx={{ width: '100%' }}
                                    renderInput={(params) => (
                                        <TextField {...params} variant="standard" />
                                    )}
                                    onChange={(_, value) =>
                                        handleAutocompleteChange(
                                            value?.externalId ?? null,
                                            'externalPositionExternalId',
                                        )
                                    }
                                />
                            </InputContainer>
                        </Grid>

                        <Grid item xs={6} sx={{ position: 'relative' }}>
                            {locationLoading && (
                                <LinearProgress
                                    sx={{
                                        position: 'absolute',
                                        left: 48,
                                        right: 0,
                                        bottom: 27,
                                    }}
                                />
                            )}
                            <InputContainer>
                                <InputLabel htmlFor="location">
                                    {t('label.location')}
                                    {mandatoryFields && mandatoryFields.LocationExternalId && (
                                        <span style={{ color: 'red' }}> *</span>
                                    )}
                                    <Error className="errorMsg" id="LocationError">
                                        {t('errorMessage.RequiredField')}
                                    </Error>
                                </InputLabel>

                                <Autocomplete
                                    id="location"
                                    openOnFocus
                                    disablePortal
                                    options={searchResults}
                                    getOptionLabel={(option: any) => option.name}
                                    onInputChange={(_, value, reason) => {
                                        if (value.length > 3 && reason === 'input') {
                                            searchLocations(value);
                                        }
                                    }}
                                    renderInput={(params) => (
                                        <TextField {...params} variant="standard" />
                                    )}
                                    onChange={(_, value) => {
                                        handleAutocompleteChange(
                                            value?.externalId,
                                            'locationExternalId',
                                        );
                                    }}
                                />
                                <p
                                    style={{
                                        margin: 0,
                                        fontSize: '11px',
                                        color: 'gray',
                                    }}
                                >
                                    {t('label.threeOrMore')}
                                </p>
                            </InputContainer>
                        </Grid>
                    </Grid>

                    <Grid container columnSpacing={6} sx={{ marginTop: '15px' }}></Grid>
                    <Grid container columnSpacing={6} sx={{ marginTop: '15px' }}>
                        <Grid item xs={6} sx={{ marginBottom: 'px', position: 'relative' }}>
                            {languageLoading && (
                                <LinearProgress
                                    sx={{
                                        position: 'absolute',
                                        left: 48,
                                        right: 0,
                                        bottom: 10,
                                    }}
                                />
                            )}
                            <InputContainer>
                                <InputLabel htmlFor="user-title">
                                    {t('label.language')}
                                    {mandatoryFields && mandatoryFields.Language && (
                                        <span style={{ color: 'red' }}> *</span>
                                    )}
                                    <Error className="errorMsg" id="LanguageError">
                                        {t('errorMessage.RequiredField')}
                                    </Error>
                                </InputLabel>
                                <Autocomplete
                                    openOnFocus
                                    disablePortal
                                    id="combo-box-demo"
                                    options={languageOptions}
                                    onChange={(_, value) =>
                                        handleAutocompleteChange(value?.value ?? null, 'language')
                                    }
                                    sx={{ width: '100%' }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            variant="standard"
                                            label={
                                                formValues.language &&
                                                formValues.language.slice(0, 2)
                                            }
                                        />
                                    )}
                                />
                            </InputContainer>
                        </Grid>
                    </Grid>
                    <div>
                        {cornerstoneLabels.length > 0 && (
                            <>
                                <Grid container columnSpacing={6} sx={{ marginTop: '15px' }}>
                                    <CornerStoneData>
                                        <legend>{t('label.csData')}</legend>
                                        {cornerstoneLabels.map((label) => {
                                            return (
                                                <Grid
                                                    item
                                                    xs={3}
                                                    sx={{
                                                        marginBottom: 'px',
                                                    }}
                                                >
                                                    <InputContainer>
                                                        <InputLabel htmlFor={label.parameter}>
                                                            {label.label}
                                                        </InputLabel>
                                                        <Input
                                                            fullWidth
                                                            id={label.parameter}
                                                            name={label.parameter}
                                                            onChange={handleInputChange}
                                                        />
                                                        <Error
                                                            className="errorMsg"
                                                            id="CSDataError"
                                                        >
                                                            {t('errorMessage.RequiredField')}
                                                        </Error>
                                                    </InputContainer>
                                                </Grid>
                                            );
                                        })}
                                    </CornerStoneData>
                                </Grid>
                            </>
                        )}
                    </div>

                    {userSupportInfo && userSupportInfo.length > 0 && (
                        <>
                            <Grid container columnSpacing={6} sx={{ marginTop: '15px' }}>
                                <CornerStoneData>
                                    <legend>User Support Info Hierarchies:</legend>
                                    {userSupportInfo.map((label: any) => {
                                        if (label !== undefined) {
                                            return (
                                                <Grid item xs={3} sx={{ marginBottom: 'px' }}>
                                                    <InputContainer>
                                                        <InputLabel htmlFor={label.parameter}>
                                                            {label.label}
                                                        </InputLabel>
                                                        <Input
                                                            fullWidth
                                                            id={label.parameter}
                                                            name={label.parameter}
                                                            onChange={handleInputChange}
                                                        />
                                                        <Error className="errorMsg" id="USIHError">
                                                            {t('errorMessage.RequiredField')}
                                                        </Error>
                                                    </InputContainer>
                                                </Grid>
                                            );
                                        }
                                    })}
                                </CornerStoneData>
                            </Grid>
                        </>
                    )}

                    {supportInfo && supportInfo.length > 0 && (
                        <>
                            <Grid container columnSpacing={6} sx={{ marginTop: '15px' }}>
                                <CornerStoneData>
                                    <legend>Support Info:</legend>
                                    {supportInfo.map((label: any) => {
                                        if (label !== undefined) {
                                            return (
                                                <Grid item xs={3} sx={{ marginBottom: 'px' }}>
                                                    <InputContainer>
                                                        <InputLabel htmlFor={label.parameter}>
                                                            {label.label}
                                                        </InputLabel>
                                                        <Input
                                                            fullWidth
                                                            id={label.parameter}
                                                            name={label.parameter}
                                                            onChange={handleInputChange}
                                                        />
                                                        <Error className="errorMsg" id="SIError">
                                                            {t('errorMessage.RequiredField')}
                                                        </Error>
                                                    </InputContainer>
                                                </Grid>
                                            );
                                        }
                                    })}
                                </CornerStoneData>
                            </Grid>
                        </>
                    )}
                    <AdHocManagersFields
                        requiredMessage={lindeManagerRequired}
                        mandatory={mandatoryFields?.LindeManagerCPID}
                        handleAutocompleteChange={handleAutocompleteChange}
                        mandatoryOnSubmit={managersMandatoryOnSubmit}
                    />

                    <Grid container columnSpacing={6} sx={{ marginTop: '15px' }}>
                        <Grid item xs={6} sx={{ position: 'relative' }}>
                            {orgUnits && (
                                <TreeSearch
                                    data={orgUnits}
                                    searchQuery={searchQuery}
                                    setSearchQuery={setSearchQuery}
                                    setSelectedItem={setSelectedItem}
                                    selectedItem={selectedItem}
                                />
                            )}
                            {orgUnits && searchQuery === '' && (
                                <TreeView
                                    children={orgUnits}
                                    selectedItem={selectedItem}
                                    setSelectedItem={setSelectedItem}
                                />
                            )}
                        </Grid>
                    </Grid>

                    <Grid container columnSpacing={4} sx={{ marginTop: '10px' }}>
                        {isSubmitLoading ? (
                            <Grid item xs={12} sx={{ textAlign: 'center', marginTop: '10px' }}>
                                <CircularProgress sx={{ width: '100%' }} />
                            </Grid>
                        ) : (
                            <>
                                <Grid item xs={12} sx={{ textAlign: 'right' }}>
                                    <Button
                                        startIcon={<SaveIcon />}
                                        type="submit"
                                        variant="outlined"
                                        onClick={handleSubmit}
                                    >
                                        {t('formField.save')}
                                    </Button>
                                </Grid>
                            </>
                        )}
                    </Grid>
                </Box>
            )}
        </>
    );
};

export default AdHocElevateOffline;
