import { useMsal } from '@azure/msal-react';
import {
    Avatar,
    Box,
    Button,
    CardActions,
    CardContent,
    CircularProgress,
    Dialog,
    Drawer,
    Grid,
    styled,
    Typography,
} from '@mui/material';
import { Stack } from '@mui/system';
import { DataGrid, GridColDef, GridSelectionModel } from '@mui/x-data-grid';
import { useContext, useEffect, useState } from 'react';
import { UserContext } from '../../context';
import { api } from '../../services';
import PlaylistRemoveIcon from '@mui/icons-material/PlaylistRemove';
import CancelPresentationIcon from '@mui/icons-material/CancelPresentation';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import ReplayIcon from '@mui/icons-material/Replay';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import LinearProgress from '@mui/material/LinearProgress';
import { generateToken } from '../../services/fetch-api/auth/authToken';
import { IEntitlementRequest, User } from '../../types';
import Dropzone from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import Moment from 'react-moment';
import jstz from 'jstz';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import MarkEmailUnreadIcon from '@mui/icons-material/MarkEmailUnread';
import ChangeCircleIcon from '@mui/icons-material/ChangeCircle';
import NoAccountsIcon from '@mui/icons-material/NoAccounts';
import RequestTypeFilter from '../../components/entitlementRequest/RequestTypeFilter';

const DataGridStyled = styled(DataGrid)(() => ({
    '& .MuiDataGrid-row': {
        cursor: 'pointer',
    },
}));

const DrawerBody = styled('div')(() => ({
    padding: '84px 20px 20px',
    fontSize: '14px',
    '& p': {
        margin: '5px 0 20px',
    },
    '& h3': {
        margin: 0,
    },
}));

const requestTypeIconMap: { [key: string]: JSX.Element } = {
    USR_CHANGE_ONLINE: <ManageAccountsIcon />,
    USR_CHANGE_OFFLINE: <ManageAccountsIcon />,
    INVITE: <MarkEmailUnreadIcon />,
    CONVERT_INVITE: <ChangeCircleIcon />,
    OFFLINE: <NoAccountsIcon />,
};

const OnlineUsers = () => {
    const { instance, accounts } = useMsal();
    const { t } = useTranslation();
    const [items, setItems] = useState<IEntitlementRequest[]>([]);
    const [selectedItem, setSelectedItem] = useState<IEntitlementRequest | null>(null);
    const [selectedUser, setSelectedUser] = useState<GridSelectionModel>([]);
    const { state, dispatch } = useContext(UserContext);
    const [open, setOpen] = useState<boolean>(false);
    const [status, setStatus] = useState<string | undefined>('');
    const [rejectLoading, setRejectLoading] = useState(false);
    const [reInviteLoading, setReInviteLoading] = useState(false);
    const [fileUpload, setNewFileUpload] = useState({
        userInfoFile: [] as File[],
    });
    const [approvalListDetails, setApprovalListDetails] = useState<any>();
    const [detailsLoading, setDetailsLoading] = useState(false);

    const timezone = jstz.determine();
    const timezoneName = timezone.name();

    const [isUploading, setIsUploading] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [filterValue, setFilterValue] = useState<string>('');

    useEffect(() => {
        if (!state.user || !state.appId) return;
        state.user.role === 'Administrator' &&
            api.entitlement
                .getBusinessSegments(state.user, state.appId, generateToken(instance, accounts))
                .then((res: any) => {
                    console.log('EO <ME IPAK ODJE', res);
                    if (!res) return;
                    dispatch({
                        type: 'SET_BUSINESS_SEGMENT',
                        data: res,
                    });
                });
    }, [state.user, state.appId]);

    const rejectRequest = (loggedUser: User, userObjectId: any) => {
        setRejectLoading(true);
        api.approvalList
            .rejectRequest(loggedUser, userObjectId, generateToken(instance, accounts))
            .then((res: any) => {
                setRejectLoading(false);
                setIsLoading(true);
                if (state.appId === 1 && state.chosenBusinessSegment.intelexRequired === 'No') {
                    state.chosenBusinessSegment !== undefined &&
                        api.approvalList
                            .getCSApprovalList(
                                state.user,
                                state.chosenBusinessSegment.internalId,
                                generateToken(instance, accounts),
                            )
                            .then((res: any) => {
                                if (!res) return;
                                setItems(res);
                                setIsLoading(false);
                            });
                } else if (state.locationCode) {
                    if (state.appId === 3) {
                        api.approvalList
                            .getLidapApprovalList(
                                state.user,
                                state.locationCode,
                                generateToken(instance, accounts),
                            )
                            .then((res: any) => {
                                if (!res) return;
                                setItems(res);
                                setIsLoading(false);
                            });
                    }
                    if (
                        state.appId === 1 &&
                        state.chosenBusinessSegment.intelexRequired === 'Yes'
                    ) {
                        state.chosenBusinessSegment !== undefined &&
                            api.approvalList
                                .getTrainingApprovalList(
                                    state.user,
                                    state.chosenBusinessSegment.internalId,
                                    state.locationCode,
                                    generateToken(instance, accounts),
                                )
                                .then((res: any) => {
                                    if (!res) return;
                                    setItems(res);
                                    setIsLoading(false);
                                });
                    }
                }
            });
        setStatus('Rejected');
    };

    const reInvite = (loggedUser: User, userObjectId: any) => {
        setReInviteLoading(true);
        api.adHocInvite
            .reinviteExpiredUser(loggedUser, userObjectId, generateToken(instance, accounts))
            .then((res) => {
                setReInviteLoading(false);
                setIsLoading(true);
                if (state.appId === 1 && state.chosenBusinessSegment.intelexRequired === 'No') {
                    state.chosenBusinessSegment !== undefined &&
                        api.approvalList
                            .getCSApprovalList(
                                state.user,
                                state.chosenBusinessSegment.internalId,
                                generateToken(instance, accounts),
                            )
                            .then((res: any) => {
                                if (!res) return;
                                setItems(res);
                                setIsLoading(false);
                            });
                } else if (state.locationCode) {
                    if (state.appId === 3) {
                        api.approvalList
                            .getLidapApprovalList(
                                state.user,
                                state.locationCode,
                                generateToken(instance, accounts),
                            )
                            .then((res: any) => {
                                if (!res) return;
                                setItems(res);
                                setIsLoading(false);
                            });
                    }
                    if (
                        state.appId === 1 &&
                        state.chosenBusinessSegment.intelexRequired === 'Yes'
                    ) {
                        state.chosenBusinessSegment !== undefined &&
                            api.approvalList
                                .getTrainingApprovalList(
                                    state.user,
                                    state.chosenBusinessSegment.internalId,
                                    state.locationCode,
                                    generateToken(instance, accounts),
                                )
                                .then((res: any) => {
                                    if (!res) return;
                                    setItems(res);
                                    setIsLoading(false);
                                });
                    }
                }
                setStatus('Invited');
            });
    };

    const onDropFile = (acceptedFiles: File[]) => {
        setNewFileUpload({ userInfoFile: acceptedFiles });
    };

    const removeFile = () => {
        setNewFileUpload({ userInfoFile: [] });
    };

    const handleSubmit = () => {
        if (
            !fileUpload.userInfoFile.length ||
            !state.businessSegment ||
            !state.chosenBusinessSegment
        )
            return;
        let file = fileUpload.userInfoFile[0];
        setIsUploading(true);
        if (state.appId === 1 && state.chosenBusinessSegment.intelexRequired === 'No') {
            // CS
            state.chosenBusinessSegment &&
                api.approvalList
                    .uploadFile(
                        file,
                        state.user,
                        state.chosenBusinessSegment.internalId,
                        state.appId.toString(),
                        state.chosenBusinessSegment.intelexRequired,
                        generateToken(instance, accounts),
                    )
                    .then((res) => {
                        setIsUploading(isUploading);
                        setOpen(false);
                        api.approvalList
                            .getCSApprovalList(
                                state.user,
                                state.chosenBusinessSegment.internalId,
                                generateToken(instance, accounts),
                            )
                            .then((res: any) => {
                                if (!res) return;
                                setItems(res);
                                //toast.success("Successfully uploaded file");
                                setNewFileUpload({ userInfoFile: [] });
                            })
                            .then(() => {
                                setIsLoading(false);
                            }); // TODO set loading to false when loading is finished
                    });
        }
        if (state.appId === 3) {
            // Intelex
            api.approvalList
                .uploadFile(
                    file,
                    state.user,
                    1,
                    state.appId.toString(),
                    state.chosenBusinessSegment.intelexRequired,
                    generateToken(instance, accounts),
                )
                .then((res) => {
                    setIsUploading(isUploading);
                    setOpen(false);
                    api.approvalList
                        .getLidapApprovalList(
                            state.user,
                            state.locationCode,
                            generateToken(instance, accounts),
                        )
                        .then((res: any) => {
                            if (!res) return;
                            setItems(res);
                            //toast.success("Successfully uploaded file");
                            setNewFileUpload({ userInfoFile: [] });
                        })
                        .then(() => {
                            setIsLoading(false);
                        });
                });
        }
        if (state.appId === 1 && state.chosenBusinessSegment.intelexRequired === 'Yes') {
            // Training
            api.approvalList
                .uploadFile(
                    file,
                    state.user,
                    state.chosenBusinessSegment.internalId,
                    state.appId.toString(),
                    state.chosenBusinessSegment.intelexRequired,
                    generateToken(instance, accounts),
                )
                .then((res) => {
                    setIsUploading(isUploading);
                    setOpen(false);
                    api.approvalList
                        .getTrainingApprovalList(
                            state.user,
                            state.chosenBusinessSegment.internalId,
                            state.locationCode,
                            generateToken(instance, accounts),
                        )
                        .then((res: any) => {
                            if (!res) return;
                            setItems(res);
                            //toast.success("Successfully uploaded file");
                            setNewFileUpload({ userInfoFile: [] });
                        })
                        .then(() => {
                            setIsLoading(false);
                        }); // TODO set loading to false when loading is finished
                });
        }
    };

    const closeDialog = () => {
        setOpen(false);
        setNewFileUpload({ userInfoFile: [] });
    };

    useEffect(() => {
        setIsLoading(true);
        if (!state.user || !instance || !accounts || !state.appId || !state.chosenBusinessSegment)
            return;

        if (state.appId === 1 && state.chosenBusinessSegment.intelexRequired === 'No') {
            state.chosenBusinessSegment !== undefined &&
                api.approvalList
                    .getCSApprovalList(
                        state.user,
                        state.chosenBusinessSegment.internalId,
                        generateToken(instance, accounts),
                    )
                    .then((res: any) => {
                        if (!res) return;
                        setItems(res);
                        setIsLoading(false);
                    });
        } else if (state.locationCode) {
            if (state.appId === 3) {
                api.approvalList
                    .getLidapApprovalList(
                        state.user,
                        state.locationCode,
                        generateToken(instance, accounts),
                    )
                    .then((res: any) => {
                        if (!res) return;
                        setItems(res);
                        setIsLoading(false);
                    });
            }
            if (state.appId === 1 && state.chosenBusinessSegment.intelexRequired === 'Yes') {
                state.chosenBusinessSegment !== undefined &&
                    api.approvalList
                        .getTrainingApprovalList(
                            state.user,
                            state.chosenBusinessSegment.internalId,
                            state.locationCode,
                            generateToken(instance, accounts),
                        )
                        .then((res: any) => {
                            if (!res) return;
                            setItems(res);
                            setIsLoading(false);
                        });
            }
        }
    }, [state.appId, state.chosenBusinessSegment]);

    useEffect(() => {
        if (!selectedUser.length) {
            setSelectedItem(null);
            return;
        }
        const item: IEntitlementRequest | null =
            items.find((i) => i.id === selectedUser[0]) || null;
        setSelectedItem(item);
        setStatus(item?.statusName);
    }, [selectedUser]);

    useEffect(() => {
        if (selectedUser[0] !== undefined) {
            setDetailsLoading(true);
            api.approvalList
                .getApprovalListDetails(
                    state.user,
                    selectedUser[0],
                    generateToken(instance, accounts),
                )
                .then((res: any) => {
                    setApprovalListDetails(res);
                    setDetailsLoading(false);
                });
        }
    }, [selectedUser]);

    const localizedGridTextsMap = {
        columnMenuUnsort: t('dataGrid.columnMenuUnsort'),
        columnMenuSortAsc: t('dataGrid.columnMenuSortAsc'),
        columnMenuSortDesc: t('dataGrid.columnMenuSortDesc'),
        columnMenuFilter: t('dataGrid.columnMenuFilter'),
        columnMenuHideColumn: t('dataGrid.columnMenuHideColumn'),
        columnMenuShowColumns: t('dataGrid.columnMenuShowColumns'),
    };

    const handleFilterChange = (value: string) => {
        setFilterValue(value);
    };

    const columns: GridColDef[] = [
        {
            field: 'requestType',
            headerName: 'Request Type',
            width: 60,
            renderCell: (params: any) => {
                const requestType = params.value as string;
                const icon = requestTypeIconMap[requestType] || null;

                return <div>{icon}</div>;
            },
            filterable: false,
            sortable: false,
            disableColumnMenu: true,
            renderHeader: () => <></>,
        },
        {
            field: 'email',
            headerName: t('formField.email'),
            width: 300,
        },
        {
            field: 'phoneNbr',
            headerName: t('formField.telephone'),
            width: 300,
        },
        {
            field: 'firstName',
            headerName: t('formField.firstName'),
            width: 200,
        },
        {
            field: 'lastName',
            headerName: t('formField.lastName'),
            width: 250,
        },
        {
            field: 'userObjectID',
            headerName: t('appList.userID'),
            width: 350,
        },
        {
            field: 'statusName',
            headerName: t('formField.status'),
            width: 180,
        },
        {
            field: 'createdAt',
            headerName: t('label.CreatedAt'),
            width: 180,
            renderCell: (params: any) =>
                params.formattedValue && (
                    <Moment format="YYYY/MM/DD HH:mm" local>
                        {params.formattedValue.toLocaleString(timezoneName)}
                    </Moment>
                ),
        },
        {
            field: 'modifiedAt',
            headerName: t('label.ModifiedAt'),
            width: 180,
            renderCell: (params: any) =>
                params.formattedValue && (
                    <Moment format="YYYY/MM/DD HH:mm" local>
                        {params.formattedValue.toLocaleString(timezoneName)}
                    </Moment>
                ),
        },
    ];

    const rows = items;

    const filteredRows = rows.filter((row) => {
        if (!filterValue) return true;
        return row.requestType === filterValue;
    });

    return (
        <>
            {state.user && state.user?.firstName && state.user?.lastName && (
                <div>
                    <Button
                        sx={{ marginBottom: 1 }}
                        variant="outlined"
                        onClick={() => setOpen(true)}
                        startIcon={<UploadFileIcon />}
                    >
                        {t('appList.bulkUpload')}
                    </Button>
                </div>
            )}

            <Dialog
                open={open}
                onClose={() => setOpen(false)}
                PaperProps={{ sx: { width: 'auto', padding: '10px' } }}
            >
                <CardContent>
                    <Typography gutterBottom variant="h6" component="div" mb={3}>
                        {t('formField.uploadFileDescription')}
                    </Typography>
                    {!isUploading ? (
                        <Typography
                            variant="body2"
                            color="text.secondary"
                            sx={{
                                border: '1px dashed',
                                borderRadius: '20px',
                                marginBottom: 2,
                            }}
                            width="100%"
                            height="100px"
                        >
                            <Dropzone onDrop={onDropFile}>
                                {({ getRootProps, getInputProps, isDragActive }) => (
                                    <div
                                        style={{
                                            height: '100%',
                                            cursor: 'pointer',
                                            textAlign: 'center',
                                            padding: '40px',
                                        }}
                                        {...getRootProps()}
                                    >
                                        <input {...getInputProps()} />
                                        <span>{t('formField.uploadFileInstruction')}</span>
                                    </div>
                                )}
                            </Dropzone>
                        </Typography>
                    ) : (
                        <Box sx={{ width: '100%' }}>
                            <LinearProgress />
                        </Box>
                    )}
                    <Typography gutterBottom variant="body2" color="text.secondary">
                        {fileUpload.userInfoFile.length > 0 && (
                            <>
                                <span>{fileUpload.userInfoFile[0]?.name}</span>
                                <DeleteOutlinedIcon onClick={removeFile} />
                            </>
                        )}
                    </Typography>
                </CardContent>
                <CardActions sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Button onClick={() => handleSubmit()} startIcon={<UploadFileIcon />}>
                        {t('formField.submit')}
                    </Button>
                    <Button onClick={() => closeDialog()}>{t('formField.cancel')}</Button>
                </CardActions>
            </Dialog>

            <Box sx={{ height: 'calc(100vh - 160px)', width: '100%' }}>
                {isLoading ? (
                    <LinearProgress />
                ) : (
                    <div style={{ position: 'relative', width: '100%', height: '100%' }}>
                        <RequestTypeFilter onFilterChange={handleFilterChange} />
                        <DataGridStyled
                            rows={filteredRows}
                            // rows={rows}
                            columns={columns}
                            pageSize={50}
                            onSelectionModelChange={(newSelectionModel) => {
                                console.log('New selection model:', newSelectionModel);
                                setSelectedUser(newSelectionModel);
                            }}
                            selectionModel={selectedUser}
                            rowsPerPageOptions={[10]}
                            localeText={localizedGridTextsMap}
                        />
                    </div>
                )}
            </Box>

            <Drawer anchor="right" open={!!selectedUser.length} onClose={() => setSelectedUser([])}>
                {approvalListDetails?.firstName && (
                    <DrawerBody>
                        <Grid
                            container
                            alignItems="center"
                            columnSpacing={2}
                            sx={{ marginBottom: 3 }}
                        >
                            <Grid item xs="auto">
                                <Avatar sx={{ bgcolor: 'blue' }}>
                                    {approvalListDetails?.firstName.charAt(0)}
                                    {(approvalListDetails?.lastName || '').charAt(0)}
                                </Avatar>
                            </Grid>
                            <Grid item xs="auto">
                                <h3>
                                    {approvalListDetails?.firstName} {approvalListDetails?.lastName}
                                </h3>
                            </Grid>
                        </Grid>
                        <Grid container columnSpacing={4}>
                            <Grid item>
                                <div>
                                    <b>{t('appList.userID')}</b>
                                    <p>{approvalListDetails?.userObjectID}</p>
                                </div>
                            </Grid>
                        </Grid>
                        <Grid container columnSpacing={4}>
                            <Grid item xs={6}>
                                <div>
                                    <b>{t('formField.email')}</b>
                                    <p>{approvalListDetails?.email}</p>
                                </div>
                            </Grid>
                        </Grid>
                        <Grid container columnSpacing={4}>
                            <Grid item xs={6}>
                                <div>
                                    <b>{t('formField.telephone')}</b>
                                    <p>{approvalListDetails?.phoneNbr}</p>
                                </div>
                            </Grid>
                            <Grid item xs={6}>
                                <div>
                                    <b>{t('formField.status')}</b>
                                    <p>{status}</p>
                                </div>
                                <div hidden={approvalListDetails?.appInfoID === 3}>
                                    <b>{t('formField.CountryCode')}</b>
                                    <p>{approvalListDetails?.countryCode}</p>
                                </div>
                            </Grid>
                        </Grid>
                        <Grid container columnSpacing={4}>
                            <Grid item xs={6}>
                                <div
                                    hidden={
                                        approvalListDetails?.appInfoID === 3 ||
                                        approvalListDetails?.entitlementRequestConfiguration[
                                            approvalListDetails?.cornerstoneConfigurationConstants[
                                                'AdminHierarchy1Label'
                                            ].toString()
                                        ] === ''
                                    }
                                >
                                    <b>
                                        {
                                            approvalListDetails?.entitlementRequestConfiguration[
                                                approvalListDetails?.cornerstoneConfigurationConstants[
                                                    'AdminHierarchy1Label'
                                                ].toString()
                                            ]
                                        }
                                    </b>
                                    <p>{approvalListDetails?.orgUnitLevel1Name}</p>
                                </div>
                                <div
                                    hidden={
                                        approvalListDetails?.appInfoID === 3 ||
                                        approvalListDetails?.entitlementRequestConfiguration[
                                            approvalListDetails?.cornerstoneConfigurationConstants[
                                                'AdminHierarchy3Label'
                                            ].toString()
                                        ] === ''
                                    }
                                >
                                    <b>
                                        {
                                            approvalListDetails?.entitlementRequestConfiguration[
                                                approvalListDetails?.cornerstoneConfigurationConstants[
                                                    'AdminHierarchy3Label'
                                                ].toString()
                                            ]
                                        }
                                    </b>
                                    <p>{approvalListDetails?.orgUnitLevel3Name}</p>
                                </div>
                                <div
                                    hidden={
                                        approvalListDetails?.appInfoID === 3 ||
                                        approvalListDetails?.entitlementRequestConfiguration[
                                            approvalListDetails?.cornerstoneConfigurationConstants[
                                                'AdminHierarchy5Label'
                                            ].toString()
                                        ] === ''
                                    }
                                >
                                    <b>
                                        {
                                            approvalListDetails?.entitlementRequestConfiguration[
                                                approvalListDetails?.cornerstoneConfigurationConstants[
                                                    'AdminHierarchy5Label'
                                                ].toString()
                                            ]
                                        }
                                    </b>
                                    <p>{approvalListDetails?.orgUnitLevel5Name}</p>
                                </div>
                            </Grid>
                            <Grid item xs={6}>
                                <div
                                    hidden={
                                        approvalListDetails?.appInfoID === 3 ||
                                        approvalListDetails?.entitlementRequestConfiguration[
                                            approvalListDetails?.cornerstoneConfigurationConstants[
                                                'AdminHierarchy2Label'
                                            ].toString()
                                        ] === ''
                                    }
                                >
                                    <b>
                                        {
                                            approvalListDetails?.entitlementRequestConfiguration[
                                                approvalListDetails?.cornerstoneConfigurationConstants[
                                                    'AdminHierarchy2Label'
                                                ].toString()
                                            ]
                                        }
                                    </b>
                                    <p>{approvalListDetails?.orgUnitLevel2Name}</p>
                                </div>
                                <div
                                    hidden={
                                        approvalListDetails?.appInfoID === 3 ||
                                        approvalListDetails?.entitlementRequestConfiguration[
                                            approvalListDetails?.cornerstoneConfigurationConstants[
                                                'AdminHierarchy4Label'
                                            ].toString()
                                        ] === ''
                                    }
                                >
                                    <b>
                                        {
                                            approvalListDetails?.entitlementRequestConfiguration[
                                                approvalListDetails?.cornerstoneConfigurationConstants[
                                                    'AdminHierarchy4Label'
                                                ].toString()
                                            ]
                                        }
                                    </b>
                                    <p>{approvalListDetails?.orgUnitLevel4Name}</p>
                                </div>
                            </Grid>
                        </Grid>
                        <Grid container columnSpacing={4}>
                            <Grid item xs={6}>
                                <div
                                    hidden={
                                        approvalListDetails?.appInfoID === 3 ||
                                        approvalListDetails?.entitlementRequestConfiguration[
                                            approvalListDetails.cornerstoneConfigurationConstants[
                                                'BusinessRelationshipIdentifiers'
                                            ].toString()
                                        ] === ''
                                    }
                                >
                                    <b>{t('formField.businessRelationship')}</b>
                                    <p>{approvalListDetails?.businessRelationshipName}</p>
                                </div>
                                <div
                                    hidden={
                                        approvalListDetails?.appInfoID === 3 ||
                                        approvalListDetails?.entitlementRequestConfiguration[
                                            approvalListDetails.cornerstoneConfigurationConstants[
                                                'LocationDisabledId'
                                            ].toString()
                                        ] === 'Yes'
                                    }
                                >
                                    <b>{t('formField.PersonnelSubarea')}</b>
                                    <p>{approvalListDetails?.locationName}</p>
                                </div>
                            </Grid>
                            <Grid item xs={6}>
                                <div
                                    hidden={
                                        approvalListDetails?.appInfoID === 3 ||
                                        approvalListDetails?.entitlementRequestConfiguration[
                                            approvalListDetails.cornerstoneConfigurationConstants[
                                                'ExternalPositionIdentifiers'
                                            ].toString()
                                        ] === ''
                                    }
                                >
                                    <b>{t('formField.LocalPosition')}</b>
                                    <p>{approvalListDetails?.externalPositionName}</p>
                                </div>
                                <div hidden={approvalListDetails?.appInfoID === 3}>
                                    <b>{t('formField.LegalEntity')}</b>
                                    <p>{approvalListDetails?.legalEntityName}</p>
                                </div>
                            </Grid>
                        </Grid>
                        <Grid container columnSpacing={4}>
                            <Grid item xs={6}>
                                <div hidden={approvalListDetails?.appInfoID === 3}>
                                    <b>{t('formField.lindeContactFirstName')}</b>
                                    <p>{approvalListDetails?.lindeContactFirstName}</p>
                                </div>
                                <div hidden={approvalListDetails?.appInfoID === 3}>
                                    <b>{t('formField.lindeContactEmail')}</b>
                                    <p>{approvalListDetails?.lindeContactEmail}</p>
                                </div>
                            </Grid>
                            <Grid item xs={6}>
                                <div hidden={approvalListDetails?.appInfoID === 3}>
                                    <b>{t('formField.lindeContactLastName')}</b>
                                    <p>{approvalListDetails?.lindeContactLastName}</p>
                                </div>
                                <div hidden={approvalListDetails?.appInfoID === 3}>
                                    <b>{t('formField.LindeManagerCPID')}</b>
                                    <p>{approvalListDetails?.lindeManagerCPID}</p>
                                </div>
                            </Grid>
                        </Grid>
                        <Grid container columnSpacing={4}>
                            <div
                                hidden={
                                    approvalListDetails?.appInfoID === 3 ||
                                    approvalListDetails?.entitlementRequestConfiguration[
                                        approvalListDetails.cornerstoneConfigurationConstants[
                                            'UserHierarchy1Label'
                                        ].toString()
                                    ] === ''
                                }
                            >
                                <b>
                                    {
                                        approvalListDetails?.entitlementRequestConfiguration[
                                            approvalListDetails.cornerstoneConfigurationConstants[
                                                'UserHierarchy1Label'
                                            ].toString()
                                        ]
                                    }
                                </b>
                                <p>{approvalListDetails?.userSupportInfoHierarchy1Name}</p>
                            </div>
                            <div
                                hidden={
                                    approvalListDetails?.appInfoID === 3 ||
                                    approvalListDetails?.entitlementRequestConfiguration[
                                        approvalListDetails.cornerstoneConfigurationConstants[
                                            'UserHierarchy2Label'
                                        ].toString()
                                    ] === ''
                                }
                            >
                                <b>
                                    {
                                        approvalListDetails?.entitlementRequestConfiguration[
                                            approvalListDetails.cornerstoneConfigurationConstants[
                                                'UserHierarchy2Label'
                                            ].toString()
                                        ]
                                    }
                                </b>
                                <p>{approvalListDetails?.userSupportInfoHierarchy2Name}</p>
                            </div>
                            <div
                                hidden={
                                    approvalListDetails?.appInfoID === 3 ||
                                    approvalListDetails?.entitlementRequestConfiguration[
                                        approvalListDetails.cornerstoneConfigurationConstants[
                                            'UserHierarchy3Label'
                                        ].toString()
                                    ] === ''
                                }
                            >
                                <b>
                                    {
                                        approvalListDetails?.entitlementRequestConfiguration[
                                            approvalListDetails.cornerstoneConfigurationConstants[
                                                'UserHierarchy3Label'
                                            ].toString()
                                        ]
                                    }
                                </b>
                                <p>{approvalListDetails?.userSupportInfoHierarchy3Name}</p>
                            </div>
                        </Grid>
                        <Grid container columnSpacing={4}>
                            <Grid item xs={6}>
                                <div
                                    hidden={
                                        approvalListDetails?.appInfoID === 3 ||
                                        approvalListDetails?.entitlementRequestConfiguration[
                                            approvalListDetails.cornerstoneConfigurationConstants[
                                                'CornerstoneData1Label'
                                            ].toString()
                                        ] === ''
                                    }
                                >
                                    <b>
                                        {
                                            approvalListDetails?.entitlementRequestConfiguration[
                                                approvalListDetails.cornerstoneConfigurationConstants[
                                                    'CornerstoneData1Label'
                                                ].toString()
                                            ]
                                        }
                                    </b>
                                    <p>{approvalListDetails?.cornerstoneData1}</p>
                                </div>
                                <div
                                    hidden={
                                        approvalListDetails?.appInfoID === 3 ||
                                        approvalListDetails?.entitlementRequestConfiguration[
                                            approvalListDetails.cornerstoneConfigurationConstants[
                                                'CornerstoneData3Label'
                                            ].toString()
                                        ] === ''
                                    }
                                >
                                    <b>
                                        {
                                            approvalListDetails?.entitlementRequestConfiguration[
                                                approvalListDetails.cornerstoneConfigurationConstants[
                                                    'CornerstoneData3Label'
                                                ].toString()
                                            ]
                                        }
                                    </b>
                                    <p>{approvalListDetails?.cornerstoneData3}</p>
                                </div>
                            </Grid>
                            <Grid item xs={6}>
                                <div
                                    hidden={
                                        approvalListDetails?.appInfoID === 3 ||
                                        approvalListDetails?.entitlementRequestConfiguration[
                                            approvalListDetails.cornerstoneConfigurationConstants[
                                                'CornerstoneData2Label'
                                            ].toString()
                                        ] === ''
                                    }
                                >
                                    <b>
                                        {
                                            approvalListDetails?.entitlementRequestConfiguration[
                                                approvalListDetails.cornerstoneConfigurationConstants[
                                                    'CornerstoneData2Label'
                                                ].toString()
                                            ]
                                        }
                                    </b>
                                    <p>{approvalListDetails?.cornerstoneData2}</p>
                                </div>
                                <div
                                    hidden={
                                        approvalListDetails?.appInfoID === 3 ||
                                        approvalListDetails?.entitlementRequestConfiguration[
                                            approvalListDetails.cornerstoneConfigurationConstants[
                                                'CornerstoneData4Label'
                                            ].toString()
                                        ] === ''
                                    }
                                >
                                    <b>
                                        {
                                            approvalListDetails?.entitlementRequestConfiguration[
                                                approvalListDetails.cornerstoneConfigurationConstants[
                                                    'CornerstoneData4Label'
                                                ].toString()
                                            ]
                                        }
                                    </b>
                                    <p>{approvalListDetails?.cornerstoneData4}</p>
                                </div>
                            </Grid>
                        </Grid>
                        <Grid container columnSpacing={4}>
                            <Grid item xs={6}>
                                <div
                                    hidden={
                                        approvalListDetails?.appInfoID === 3 ||
                                        approvalListDetails?.entitlementRequestConfiguration[
                                            approvalListDetails.cornerstoneConfigurationConstants[
                                                'SupportInfo1Label'
                                            ].toString()
                                        ] === ''
                                    }
                                >
                                    <b>
                                        {
                                            approvalListDetails?.entitlementRequestConfiguration[
                                                approvalListDetails.cornerstoneConfigurationConstants[
                                                    'SupportInfo1Label'
                                                ].toString()
                                            ]
                                        }
                                    </b>
                                    <p>{approvalListDetails?.supportInfo1}</p>
                                </div>
                                <div
                                    hidden={
                                        approvalListDetails?.appInfoID === 3 ||
                                        approvalListDetails?.entitlementRequestConfiguration[
                                            approvalListDetails.cornerstoneConfigurationConstants[
                                                'SupportInfo2Label'
                                            ].toString()
                                        ] === ''
                                    }
                                >
                                    <b>
                                        {
                                            approvalListDetails?.entitlementRequestConfiguration[
                                                approvalListDetails.cornerstoneConfigurationConstants[
                                                    'SupportInfo2Label'
                                                ].toString()
                                            ]
                                        }
                                    </b>
                                    <p>{approvalListDetails?.supportInfo2}</p>
                                </div>
                                <div
                                    hidden={
                                        approvalListDetails?.appInfoID === 3 ||
                                        approvalListDetails?.entitlementRequestConfiguration[
                                            approvalListDetails.cornerstoneConfigurationConstants[
                                                'SupportInfo3Label'
                                            ].toString()
                                        ] === ''
                                    }
                                >
                                    <b>
                                        {
                                            approvalListDetails?.entitlementRequestConfiguration[
                                                approvalListDetails.cornerstoneConfigurationConstants[
                                                    'SupportInfo3Label'
                                                ].toString()
                                            ]
                                        }
                                    </b>
                                    <p>{approvalListDetails?.supportInfo3}</p>
                                </div>
                            </Grid>
                        </Grid>
                        <Grid container columnSpacing={4}>
                            <Grid item xs={6}>
                                <div
                                    hidden={
                                        approvalListDetails?.appInfoID != 3 &&
                                        approvalListDetails?.appInfoID != 8
                                    }
                                >
                                    <b>{t('formField.Location')}</b>
                                    <p>{approvalListDetails?.intelexLocationObject?.name}</p>
                                </div>
                                <div
                                    hidden={
                                        approvalListDetails?.appInfoID != 3 &&
                                        approvalListDetails?.appInfoID != 8
                                    }
                                >
                                    <b>{t('formField.SupervisorNumber')}</b>
                                    <p>{approvalListDetails?.supervisorDisplayName}</p>
                                </div>
                            </Grid>
                            <Grid item xs={6}>
                                <div
                                    hidden={
                                        approvalListDetails?.appInfoID != 3 &&
                                        approvalListDetails?.appInfoID != 8
                                    }
                                >
                                    <b>{t('formField.PositionTitle')}</b>
                                    <p>{approvalListDetails?.positionTitle}</p>
                                </div>
                                <div
                                    hidden={
                                        approvalListDetails?.appInfoID != 3 &&
                                        approvalListDetails?.appInfoID != 8
                                    }
                                >
                                    <b>{t('formField.myCompany')}</b>
                                    <p>{approvalListDetails?.company}</p>
                                </div>
                            </Grid>
                        </Grid>
                        <Stack direction="row" spacing={2}>
                            <Button
                                variant="outlined"
                                startIcon={<CancelPresentationIcon />}
                                onClick={() => setSelectedUser([])}
                            >
                                {t('button.close')}
                            </Button>
                            {rejectLoading ? (
                                <CircularProgress />
                            ) : (
                                <Button
                                    variant="contained"
                                    startIcon={<PlaylistRemoveIcon />}
                                    disabled={[1, 3, 6].includes(
                                        approvalListDetails?.statusId || 0,
                                    )}
                                    onClick={() =>
                                        rejectRequest(state.user, approvalListDetails?.id)
                                    }
                                >
                                    {t('formField.RejectRequest')}
                                </Button>
                            )}

                            {reInviteLoading ? (
                                <CircularProgress />
                            ) : (
                                <Button
                                    variant="contained"
                                    startIcon={<ReplayIcon />}
                                    onClick={() => reInvite(state.user, approvalListDetails?.id)}
                                >
                                    {t('label.reInviteUser')}
                                </Button>
                            )}
                        </Stack>
                    </DrawerBody>
                )}
            </Drawer>
        </>
    );
};

export default OnlineUsers;
