import { useMsal } from '@azure/msal-react';
import { useContext } from 'react';
import { UserContext } from '../../../context';
import { useAsyncTrigger } from '../../../hooks/useAsyncTrigger';
import { api } from '../../../services';
import { generateToken } from '../../../services/fetch-api/auth/authToken';
import { LindeManager } from '../../../types/lindeManagers';

export interface SearchManagerForm {
    lastName: string;
    firstName: string;
    email: string;
    user_ref: string;
    cornerstoneID: string;
}

function mapResponseToOptions(response: LindeManager[]): LindeManagerOption[] {
    return response.map(
        ({ user_ref: externalId, user_id: id, user_name_first, user_name_last, user_email }) => ({
            externalId,
            id,
            label: `${user_name_first}  ${user_name_last}${user_email ? ` ( ${user_email} )` : ''}`,
        }),
    );
}

interface LindeManagerOption {
    externalId: string;
    id: number;
    label: string;
}

interface UseGetLindeManagersState {
    lindeManagers: LindeManagerOption[];
    isLindeManagersLoading: boolean;
    getLindeManagers: (searchParams: SearchManagerForm, onComplete: () => void) => void;
}

export const useGetLindeManagers = (): UseGetLindeManagersState => {
    const {
        state: { user },
    } = useContext(UserContext);
    const { instance, accounts } = useMsal();
    const {
        result: lindeManagers = [],
        trigger: getLindeManagers,
        isLoading: isLindeManagersLoading,
    } = useAsyncTrigger<
        UseGetLindeManagersState['lindeManagers'],
        Parameters<UseGetLindeManagersState['getLindeManagers']>
    >(async (_, managersSearchValues, onComplete) => {
        if (!user) {
            throw new Error('User must be defined');
        }

        const { data: lindeManagers } = await api.adHocInvite.getLindeManagers(
            user,
            generateToken(instance, accounts),
            managersSearchValues,
        );

        onComplete();
        return mapResponseToOptions(lindeManagers);
    });

    return { lindeManagers, getLindeManagers, isLindeManagersLoading };
};
