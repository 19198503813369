import { useMsal } from '@azure/msal-react';
import {
    Avatar,
    Box,
    Button,
    CardActions,
    CardContent,
    Dialog,
    Drawer,
    Grid,
    styled,
    Typography,
    Modal,
    InputLabel,
    Input,
    CircularProgress,
    Autocomplete,
    TextField,
} from '@mui/material';
import { Stack } from '@mui/system';
import { DataGrid, GridColDef, GridSelectionModel } from '@mui/x-data-grid';
import { useContext, useEffect, useRef, useState } from 'react';
import { UserContext } from '../../context';
import { api } from '../../services';
import PlaylistRemoveIcon from '@mui/icons-material/PlaylistRemove';
import ReplayIcon from '@mui/icons-material/Replay';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import LinearProgress from '@mui/material/LinearProgress';
import { generateToken } from '../../services/fetch-api/auth/authToken';
import { IEntitlementRequest, User } from '../../types';
import Dropzone from 'react-dropzone';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import Moment from 'react-moment';
import jstz from 'jstz';
import * as signalR from '@microsoft/signalr';
import Progress from '../../components/progressBar';
import { darken, lighten } from '@mui/material/styles';
import { Flip } from '@mui/icons-material';
import { InputContainer, styleContactModal, Error } from '../../styled';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import MarkEmailUnreadIcon from '@mui/icons-material/MarkEmailUnread';
import ChangeCircleIcon from '@mui/icons-material/ChangeCircle';
import NoAccountsIcon from '@mui/icons-material/NoAccounts';
import RequestTypeFilter from '../../components/entitlementRequest/RequestTypeFilter';

const getBackgroundColor = (color: string, mode: string) =>
    mode === 'dark' ? darken(color, 0.7) : lighten(color, 0.7);

const getHoverBackgroundColor = (color: string, mode: string) =>
    mode === 'dark' ? darken(color, 0.6) : lighten(color, 0.6);

const getSelectedBackgroundColor = (color: string, mode: string) =>
    mode === 'dark' ? darken(color, 0.5) : lighten(color, 0.5);

const getSelectedHoverBackgroundColor = (color: string, mode: string) =>
    mode === 'dark' ? darken(color, 0.4) : lighten(color, 0.4);

const DataGridStyled = styled(DataGrid)(({ theme }) => ({
    '& .super-app-theme--7': {
        backgroundColor: getBackgroundColor(theme.palette.warning.main, theme.palette.mode),
        '&:hover': {
            backgroundColor: getHoverBackgroundColor(
                theme.palette.warning.main,
                theme.palette.mode,
            ),
        },
        '&.Mui-selected': {
            backgroundColor: getSelectedBackgroundColor(
                theme.palette.warning.main,
                theme.palette.mode,
            ),
            '&:hover': {
                backgroundColor: getSelectedHoverBackgroundColor(
                    theme.palette.warning.main,
                    theme.palette.mode,
                ),
            },
        },
    },
}));

const DrawerBody = styled('div')(() => ({
    padding: '84px 20px 20px',
    fontSize: '14px',
    '& p': {
        margin: '5px 0 20px',
    },
    '& h3': {
        margin: 0,
    },
}));

const requestTypeIconMap: { [key: string]: JSX.Element } = {
    USR_CHANGE_ONLINE: <ManageAccountsIcon />,
    USR_CHANGE_OFFLINE: <ManageAccountsIcon />,
    INVITE: <MarkEmailUnreadIcon />,
    CONVERT_INVITE: <ChangeCircleIcon />,
    OFFLINE: <NoAccountsIcon />,
};

const OfflineUsers = () => {
    const { instance, accounts } = useMsal();
    const { t } = useTranslation();
    const [items, setItems] = useState<IEntitlementRequest[]>([]);
    const [selectedItem, setSelectedItem] = useState<IEntitlementRequest | null>(null);
    const [selectedUser, setSelectedUser] = useState<GridSelectionModel>([]);
    const { state, dispatch } = useContext(UserContext);
    const [openOffline, setOpenOffline] = useState<boolean>(false);
    const [status, setStatus] = useState<string | undefined>('');
    const [fileUploadOffline, setNewFileUploadOffline] = useState({
        userInfoFile: [] as File[],
    });
    const timezone = jstz.determine();
    const timezoneName = timezone.name();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [approvalListDetails, setApprovalListDetails] = useState<any>();
    const [detailsLoading, setDetailsLoading] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);
    // const [email, setEmail] = useState<string>('');
    const [flipLoading, setFlipLoading] = useState<boolean>(false);
    const [intelexLocations, setIntelexLocations] = useState([]);
    const [intelexLocationLoading, setIntelexLocationLoading] = useState(false);
    const [supervisors, setSupervisors] = useState([]);
    const [supervisorLoading, setSupervisorLoading] = useState(false);
    const supervisorsInput = useRef<HTMLElement>(null);
    const [formValues, setFormValues] = useState({
        newEmail: '',
        positionTitle: '',
        myCompany: '',
        intelexLocation: '',
        supervisorNumber: '',
    });
    const [filterValue, setFilterValue] = useState<string>('');

    function handleCloseModal() {
        setModalOpen(false);
        approvalListDetails.email
            ? setFormValues({
                  newEmail: approvalListDetails.email,
                  positionTitle: '',
                  myCompany: '',
                  intelexLocation: '',
                  supervisorNumber: '',
              })
            : setFormValues({
                  newEmail: '',
                  positionTitle: '',
                  myCompany: '',
                  intelexLocation: '',
                  supervisorNumber: '',
              });
    }

    function isValidEmail(email: string) {
        const pattern = /^\S+@\S+\.\S+$/;
        return pattern.test(email);
    }

    function progressBar() {
        const connection = new signalR.HubConnectionBuilder().withUrl('/progressHub').build();

        connection.on('progressUpdated', (message: string, percentage: number) => {
            dispatch({
                type: 'SET_PROGRESS',
                data: percentage,
            });

            dispatch({
                type: 'SET_PROGRESS_LABEL',
                data: message,
            });
        });

        connection.start();
    }

    useEffect(() => {
        if (!state.user || !state.appId) return;
        state.user.role === 'Administrator' &&
            api.entitlement
                .getBusinessSegments(state.user, state.appId, generateToken(instance, accounts))
                .then((res: any) => {
                    console.log('no, ipak sam odje', res);
                    if (!res) return;
                    dispatch({
                        type: 'SET_BUSINESS_SEGMENT',
                        data: res,
                    });
                });
    }, [state.user, state.appId]);

    const rejectRequest = (loggedUser: User, userObjectId: any) => {
        api.approvalList
            .rejectRequest(loggedUser, userObjectId, generateToken(instance, accounts))
            .then((res: any) => {
                toast.success('OK');
                setIsLoading(true);
                setSelectedUser([]);
                api.approvalList
                    .getOfflineApprovalList(
                        state.user,
                        state.chosenBusinessSegment.internalId,
                        generateToken(instance, accounts),
                    )
                    .then((res: any) => {
                        if (!res) return;
                        setItems(res);
                        setIsLoading(false);
                    });
                if (res?.status && res.status >= 400) {
                    toast.error('Cannot reject request, please try again later');
                }
            });
        //PROVERI ZASTO JE OVDE RES UNDEFINED I STAVI DA SE OVO ISPOD DESI SAMO AKO JE REQUEST USPESAN
        setStatus('Rejected');
    };

    const onDropFileOffline = (acceptedFiles: File[]) => {
        setNewFileUploadOffline({ userInfoFile: acceptedFiles });
    };

    const removeFileOffline = () => {
        setNewFileUploadOffline({ userInfoFile: [] });
    };

    const handleReprocess = (id: any) => {
        let ids = [id];
        api.approvalList
            .approveOfflineEntitlement(state.user, ids, generateToken(instance, accounts))
            .then((res: any) => {
                setSelectedUser([]);
                toast.success('Reprocess Successful');
                setIsLoading(true);
                api.approvalList
                    .getOfflineApprovalList(
                        state.user,
                        state.chosenBusinessSegment.internalId,
                        generateToken(instance, accounts),
                    )
                    .then((res: any) => {
                        if (!res) return;
                        setItems(res);
                        setIsLoading(false);
                    });
            })
            .catch((err: any) => {
                toast.error(err.message);
            });
    };

    const handleSubmitOffline = () => {
        if (!fileUploadOffline.userInfoFile.length || !state.businessSegment) return;
        dispatch({ type: 'SET_PROGRESS_ACTIVE', data: true });
        let file = fileUploadOffline.userInfoFile[0];
        setOpenOffline(false);
        state.chosenBusinessSegment &&
            api.approvalList
                .uploadFileOffline(
                    file,
                    state.user,
                    state.chosenBusinessSegment.internalId,
                    '1',
                    state.chosenBusinessSegment.intelexRequired,
                    generateToken(instance, accounts),
                    dispatch,
                )
                .then((res: any) => {
                    setOpenOffline(false);
                    api.approvalList
                        .approveOfflineEntitlement(
                            state.user,
                            res.data,
                            generateToken(instance, accounts),
                        )
                        .then((res: any) => {
                            dispatch({
                                type: 'SET_PROGRESS_ACTIVE',
                                data: false,
                            });
                            dispatch({
                                type: 'SET_IS_APPROVALLIST_LOADING',
                                data: true,
                            });
                            toast.success('Upload complete');
                            api.approvalList
                                .getOfflineApprovalList(
                                    state.user,
                                    state.chosenBusinessSegment.internalId,
                                    generateToken(instance, accounts),
                                )
                                .then((res: any) => {
                                    if (!res) return;
                                    setItems(res);
                                    dispatch({
                                        type: 'SET_IS_APPROVALLIST_LOADING',
                                        data: false,
                                    });
                                });
                        })
                        .catch((err: any) => {
                            toast.error(err.message);
                        });
                });
    };

    const handleFlipOfflineToOnline = () => {
        let hasError = false;
        let requiredEmail: any = document.getElementById('EmailError');
        let invalidEmail: any = document.getElementById('EmailInvalid');
        let requiredPosition: any = document.getElementById('TitleError');
        let requiredCompany: any = document.getElementById('CompanyError');
        let requiredLocation: any = document.getElementById('IntelexLocationError');
        let requiredSupervisor: any = document.getElementById('SupervisorError');

        if (formValues.newEmail.length === 0) {
            requiredEmail.style.display = 'inline';
            invalidEmail.style.display = 'none';
            hasError = true;
        } else if (isValidEmail(formValues.newEmail) === false) {
            invalidEmail.style.display = 'inline';
            requiredEmail.style.display = 'none';
            hasError = true;
        }
        if (
            formValues.positionTitle.length === 0 &&
            state.chosenBusinessSegment.intelexRequired === 'Yes'
        ) {
            requiredPosition.style.display = 'inline';
            hasError = true;
        }
        if (
            formValues.myCompany.length === 0 &&
            state.chosenBusinessSegment.intelexRequired === 'Yes'
        ) {
            requiredCompany.style.display = 'inline';
            hasError = true;
        }
        if (
            formValues.intelexLocation.length === 0 &&
            state.chosenBusinessSegment.intelexRequired === 'Yes'
        ) {
            requiredLocation.style.display = 'inline';
            hasError = true;
        }
        if (
            formValues.supervisorNumber.length === 0 &&
            state.chosenBusinessSegment.intelexRequired === 'Yes'
        ) {
            requiredSupervisor.style.display = 'inline';
            hasError = true;
        }

        if (hasError) {
            return;
        }

        setFlipLoading(true);
        api.entitlement
            .flipOfflineToOnline(
                selectedUser[0],
                formValues,
                state.user,
                generateToken(instance, accounts),
            )
            .then((res: any) => {
                setSelectedUser([]);
                api.approvalList
                    .getOfflineApprovalList(
                        state.user,
                        state.chosenBusinessSegment.internalId,
                        generateToken(instance, accounts),
                    )
                    .then((res: any) => {
                        setFlipLoading(false);
                        setModalOpen(false);
                        // setEmail('');
                        setFormValues({
                            newEmail: '',
                            positionTitle: '',
                            myCompany: '',
                            intelexLocation: '',
                            supervisorNumber: '',
                        });
                        if (!res) return;
                        setItems(res);
                    });
            });
    };

    const closeDialogOffline = () => {
        setOpenOffline(false);
        setNewFileUploadOffline({ userInfoFile: [] });
    };

    useEffect(() => {
        dispatch({ type: 'SET_IS_APPROVALLIST_LOADING', data: true });
        if (!state.user || !instance || !accounts || !state.appId) return;
        state.chosenBusinessSegment !== undefined &&
            api.approvalList
                .getOfflineApprovalList(
                    state.user,
                    state.chosenBusinessSegment.internalId,
                    generateToken(instance, accounts),
                )
                .then((res: any) => {
                    if (!res) return;
                    setItems(res);
                    dispatch({
                        type: 'SET_IS_APPROVALLIST_LOADING',
                        data: false,
                    });
                });
    }, [state.appId, state.chosenBusinessSegment]);

    let timeoutId: any = null;
    const handleIntelexLocationQuery = (event: any) => {
        clearTimeout(timeoutId);
        timeoutId = setTimeout(() => {
            if (event.target.value !== undefined && event.target.value.length > 3) {
                setIntelexLocationLoading(true);
                api.adHocInvite
                    .getLocationsIntelex(
                        state.user,
                        event.target.value,
                        generateToken(instance, accounts),
                    )
                    .then((res: any) => {
                        setIntelexLocationLoading(false);
                        setIntelexLocations(res);
                    });
            }
        }, 300);
    };

    const handleSupervisorsChange = (event: any) => {
        clearTimeout(timeoutId);
        timeoutId = setTimeout(() => {
            if (event.target.value !== undefined && event.target.value.length > 3) {
                setSupervisorLoading(true);
                api.adHocInvite
                    .getSupervisors(
                        state.user,
                        event.target.value,
                        generateToken(instance, accounts),
                    )
                    .then((res: any) => {
                        if (!res) return;
                        setSupervisorLoading(false);
                        setSupervisors(
                            res.map((supervisor: any) => ({
                                id: supervisor.number,
                                name: `${supervisor.fullName} (${supervisor.number})${
                                    supervisor.email ? ' - ' + supervisor.email : ''
                                }`,
                            })),
                        );
                        supervisorsInput.current?.focus();
                    });
            }
        }, 300);
    };

    useEffect(() => {
        if (!selectedUser.length) {
            setSelectedItem(null);
            return;
        }
        const item: IEntitlementRequest | null =
            items.find((i) => i.id === selectedUser[0]) || null;
        setSelectedItem(item);
        setStatus(item?.statusName);
    }, [selectedUser]);

    useEffect(() => {
        if (selectedUser[0] !== undefined) {
            setDetailsLoading(true);
            api.approvalList
                .getApprovalListDetails(
                    state.user,
                    selectedUser[0],
                    generateToken(instance, accounts),
                )
                .then((res: any) => {
                    setApprovalListDetails(res);
                    res.email
                        ? setFormValues({ ...formValues, newEmail: res.email })
                        : setFormValues({ ...formValues, newEmail: '' });
                    setDetailsLoading(false);
                });
        }
    }, [selectedUser]);

    const localizedGridTextsMap = {
        columnMenuUnsort: t('dataGrid.columnMenuUnsort'),
        columnMenuSortAsc: t('dataGrid.columnMenuSortAsc'),
        columnMenuSortDesc: t('dataGrid.columnMenuSortDesc'),
        columnMenuFilter: t('dataGrid.columnMenuFilter'),
        columnMenuHideColumn: t('dataGrid.columnMenuHideColumn'),
        columnMenuShowColumns: t('dataGrid.columnMenuShowColumns'),
    };

    const handleFilterChange = (value: string) => {
        setFilterValue(value);
    };

    const columns: GridColDef[] = [
        {
            field: 'requestType',
            headerName: 'Request Type',
            width: 60,
            renderCell: (params: any) => {
                const requestType = params.value as string;
                const icon = requestTypeIconMap[requestType] || null;

                return <div>{icon}</div>;
            },
            filterable: false,
            sortable: false,
            disableColumnMenu: true,
            renderHeader: () => <></>,
        },
        {
            field: 'firstName',
            headerName: t('formField.firstName'),
            width: 200,
        },
        {
            field: 'lastName',
            headerName: t('formField.lastName'),
            width: 250,
        },
        {
            field: 'userObjectID',
            headerName: t('appList.userID'),
            width: 350,
        },
        {
            field: 'statusName',
            headerName: t('formField.status'),
            width: 180,
        },
        {
            field: 'createdAt',
            headerName: t('label.CreatedAt'),
            width: 180,
            renderCell: (params: any) =>
                params.formattedValue && (
                    <Moment format="YYYY/MM/DD HH:mm" local>
                        {params.formattedValue.toLocaleString(timezoneName)}
                    </Moment>
                ),
        },
        {
            field: 'modifiedAt',
            headerName: t('label.ModifiedAt'),
            width: 180,
            renderCell: (params: any) =>
                params.formattedValue && (
                    <Moment format="YYYY/MM/DD HH:mm" local>
                        {params.formattedValue.toLocaleString(timezoneName)}
                    </Moment>
                ),
        },
    ];

    const rows = items;

    const filteredRows = rows.filter((row) => {
        if (!filterValue) return true;
        return row.requestType === filterValue;
    });

    return (
        <>
            {state.user && state.user?.firstName && state.user?.lastName && (
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                    }}
                >
                    <Button
                        disabled={state.progressActive ? true : false}
                        sx={{ marginBottom: 1, marginLeft: 2 }}
                        variant="outlined"
                        onClick={() => {
                            setOpenOffline(true);
                            progressBar();
                        }}
                        startIcon={<UploadFileIcon />}
                    >
                        {t('appList.bulkUpload')}
                    </Button>
                    {state.progressActive && (
                        <div>
                            <p style={{ margin: 0, fontSize: '12px' }}>{state.progressMessage}</p>
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    height: '22px',
                                }}
                            >
                                {' '}
                                <p
                                    style={{
                                        fontSize: '.7rem',
                                        marginRight: '10px',
                                    }}
                                >
                                    {Math.floor(state.progress)}%
                                </p>{' '}
                                <Progress progress={state.progress} />
                            </div>
                        </div>
                    )}
                </div>
            )}

            <Dialog
                open={openOffline}
                onClose={() => setOpenOffline(false)}
                PaperProps={{ sx: { width: 'auto', padding: '10px' } }}
            >
                <CardContent>
                    <Typography gutterBottom variant="h6" component="div" mb={3}>
                        {t('formField.uploadFileDescription') + ' ' + '(offline)'}
                    </Typography>
                    <Typography
                        variant="body2"
                        color="text.secondary"
                        sx={{
                            border: '1px dashed',
                            borderRadius: '20px',
                            marginBottom: 2,
                        }}
                        width="100%"
                        height="100px"
                    >
                        <Dropzone onDrop={onDropFileOffline}>
                            {({ getRootProps, getInputProps, isDragActive }) => (
                                <div
                                    style={{
                                        height: '100%',
                                        cursor: 'pointer',
                                        textAlign: 'center',
                                        padding: '40px',
                                    }}
                                    {...getRootProps()}
                                >
                                    <input {...getInputProps()} />
                                    <span>
                                        {t('formField.uploadFileInstruction') + ' ' + '(offline)'}
                                    </span>
                                </div>
                            )}
                        </Dropzone>
                    </Typography>
                    <Typography gutterBottom variant="body2" color="text.secondary">
                        {fileUploadOffline.userInfoFile.length > 0 && (
                            <>
                                <span>{fileUploadOffline.userInfoFile[0]?.name}</span>
                                <DeleteOutlinedIcon onClick={removeFileOffline} />
                            </>
                        )}
                    </Typography>
                </CardContent>
                <CardActions sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Button onClick={() => handleSubmitOffline()} startIcon={<UploadFileIcon />}>
                        {t('formField.submit')}
                    </Button>
                    <Button onClick={() => closeDialogOffline()}>{t('formField.cancel')}</Button>
                </CardActions>
            </Dialog>

            <Box sx={{ height: 'calc(100vh - 160px)', width: '100%' }}>
                {state.approvalListLoading ? (
                    <LinearProgress />
                ) : (
                    <div style={{ position: 'relative', width: '100%', height: '100%' }}>
                        <RequestTypeFilter onFilterChange={handleFilterChange} />
                        <DataGridStyled
                            rows={filteredRows}
                            columns={columns}
                            pageSize={50}
                            onSelectionModelChange={(newSelectionModel) => {
                                setSelectedUser(newSelectionModel);
                            }}
                            selectionModel={selectedUser}
                            rowsPerPageOptions={[10]}
                            localeText={localizedGridTextsMap}
                            getRowClassName={(params) => `super-app-theme--${params.row.statusId}`}
                        />
                    </div>
                )}
            </Box>

            <Drawer anchor="right" open={!!selectedUser.length} onClose={() => setSelectedUser([])}>
                {approvalListDetails?.firstName && (
                    <DrawerBody>
                        <Grid
                            container
                            alignItems="center"
                            columnSpacing={2}
                            sx={{ marginBottom: 3 }}
                        >
                            <Grid item xs="auto">
                                <Avatar sx={{ bgcolor: 'blue' }}>
                                    {approvalListDetails.firstName.charAt(0)}
                                    {(approvalListDetails.lastName || '').charAt(0)}
                                </Avatar>
                            </Grid>
                            <Grid item xs="auto">
                                <h3>
                                    {approvalListDetails.firstName} {approvalListDetails.lastName}
                                </h3>
                            </Grid>
                        </Grid>
                        <Modal open={modalOpen} onClose={handleCloseModal}>
                            <Box sx={styleContactModal}>
                                <h3>Switch to Online user</h3>
                                <InputContainer>
                                    <InputLabel htmlFor="user-email">
                                        {t('formField.email')}
                                        <span style={{ color: 'red' }}> *</span>
                                        <Error className="errorMsg" id="EmailError">
                                            {t('errorMessage.RequiredField')}
                                        </Error>
                                        <Error className="errorMsg" id="EmailInvalid">
                                            {t('errorMessage.InvalidInput')}
                                        </Error>
                                    </InputLabel>
                                    <Input
                                        fullWidth
                                        id={'email'}
                                        name={'email'}
                                        onChange={(event) => {
                                            setFormValues({
                                                ...formValues,
                                                newEmail: event.target.value,
                                            });
                                        }}
                                        defaultValue={approvalListDetails.email}
                                    />
                                    <Error className="errorMsg" id="EmailError">
                                        {t('errorMessage.RequiredField')}
                                    </Error>
                                    <Error className="errorMsg" id="EmailInvalid">
                                        {t('errorMessage.InvalidInput')}
                                    </Error>
                                </InputContainer>
                                {state.chosenBusinessSegment.intelexRequired === 'Yes' && (
                                    <>
                                        <InputContainer>
                                            <InputLabel htmlFor="user-title">
                                                {t('formField.PositionTitle')}
                                                <span style={{ color: 'red' }}> *</span>
                                                <Error className="errorMsg" id="TitleError">
                                                    {t('errorMessage.RequiredField')}
                                                </Error>
                                            </InputLabel>
                                            <Input
                                                fullWidth
                                                id="user-title"
                                                name="positionTitle"
                                                onChange={(event) => {
                                                    setFormValues({
                                                        ...formValues,
                                                        positionTitle: event.target.value,
                                                    });
                                                }}
                                            />
                                        </InputContainer>
                                        <InputContainer>
                                            <InputLabel htmlFor="user-company">
                                                {t('formField.myCompany')}
                                                <span style={{ color: 'red' }}> *</span>
                                                <Error className="errorMsg" id="CompanyError">
                                                    {t('errorMessage.RequiredField')}
                                                </Error>
                                            </InputLabel>
                                            <Input
                                                fullWidth
                                                id="user-company"
                                                name="company"
                                                onChange={(event) => {
                                                    setFormValues({
                                                        ...formValues,
                                                        myCompany: event.target.value,
                                                    });
                                                }}
                                            />
                                        </InputContainer>
                                        <Grid item xs={6} sx={{ position: 'relative' }}>
                                            {intelexLocationLoading && (
                                                <LinearProgress
                                                    sx={{
                                                        position: 'absolute',
                                                        left: 48,
                                                        right: 0,
                                                        bottom: 27,
                                                    }}
                                                />
                                            )}
                                            <InputContainer>
                                                <InputLabel htmlFor="location">
                                                    {t('label.intelexLocation')}
                                                    <span style={{ color: 'red' }}> *</span>
                                                    <Error
                                                        className="errorMsg"
                                                        id="IntelexLocationError"
                                                    >
                                                        {t('errorMessage.RequiredField')}
                                                    </Error>
                                                </InputLabel>

                                                <Autocomplete
                                                    id="locationIntelex"
                                                    openOnFocus
                                                    disablePortal
                                                    options={intelexLocations}
                                                    getOptionLabel={(option: any) => option.name}
                                                    onInputChange={(event) =>
                                                        handleIntelexLocationQuery(event)
                                                    }
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            variant="standard"
                                                            label={t('label.search')}
                                                        />
                                                    )}
                                                    onChange={(event: any, value: any) =>
                                                        setFormValues({
                                                            ...formValues,
                                                            intelexLocation: value.code,
                                                        })
                                                    }
                                                />
                                                <p
                                                    style={{
                                                        margin: 0,
                                                        fontSize: '11px',
                                                        color: 'gray',
                                                    }}
                                                >
                                                    {t('label.threeOrMore')}
                                                </p>
                                            </InputContainer>
                                        </Grid>
                                        <Grid item xs sx={{ position: 'relative' }}>
                                            <InputContainer>
                                                {supervisorLoading && (
                                                    <LinearProgress
                                                        sx={{
                                                            position: 'absolute',
                                                            left: 48,
                                                            right: 0,
                                                            bottom: 27,
                                                        }}
                                                    />
                                                )}
                                                <InputLabel htmlFor="supervisor">
                                                    {t('label.supervisorNumber')}
                                                    <span style={{ color: 'red' }}> *</span>
                                                    <Error
                                                        className="errorMsg"
                                                        id="SupervisorError"
                                                    >
                                                        {t('errorMessage.RequiredField')}
                                                    </Error>
                                                </InputLabel>

                                                <Autocomplete
                                                    id="supervisor"
                                                    openOnFocus
                                                    disablePortal
                                                    options={supervisors}
                                                    getOptionLabel={(option: any) => option.name}
                                                    onInputChange={(event) =>
                                                        handleSupervisorsChange(event)
                                                    }
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            variant="standard"
                                                            label={t('label.search')}
                                                        />
                                                    )}
                                                    onChange={(event: any, value: any) =>
                                                        setFormValues({
                                                            ...formValues,
                                                            supervisorNumber: value.id,
                                                        })
                                                    }
                                                />
                                                <p
                                                    style={{
                                                        margin: 0,
                                                        fontSize: '11px',
                                                        color: 'gray',
                                                    }}
                                                >
                                                    {t('label.threeOrMore')}
                                                </p>
                                            </InputContainer>
                                        </Grid>
                                    </>
                                )}
                                <div
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        marginTop: 40,
                                        width: 200,
                                        marginLeft: 'auto',
                                        marginRight: 'auto',
                                    }}
                                >
                                    <Button variant="contained" onClick={() => setModalOpen(false)}>
                                        Cancel
                                    </Button>
                                    {!flipLoading ? (
                                        <Button
                                            variant="contained"
                                            onClick={handleFlipOfflineToOnline}
                                        >
                                            Confirm
                                        </Button>
                                    ) : (
                                        <CircularProgress size={30} />
                                    )}
                                </div>
                            </Box>
                        </Modal>
                        <Grid container columnSpacing={4}>
                            <Grid item>
                                <div>
                                    <b>{t('appList.userID')}</b>
                                    <p>{approvalListDetails?.userObjectID}</p>
                                </div>
                            </Grid>
                        </Grid>
                        <Grid container columnSpacing={4}>
                            <Grid item xs={6}>
                                <div>
                                    <b>{t('formField.email')}</b>
                                    <p>{approvalListDetails.email}</p>
                                </div>
                            </Grid>
                        </Grid>
                        <Grid container columnSpacing={4}>
                            <Grid item xs={6}>
                                <div>
                                    <b>{t('formField.telephone')}</b>
                                    <p>{approvalListDetails.phoneNbr}</p>
                                </div>
                            </Grid>
                            <Grid item xs={6}>
                                <div>
                                    <b>{t('formField.status')}</b>
                                    <p>{status}</p>
                                </div>
                                <div hidden={approvalListDetails.appInfoID === 3}>
                                    <b>{t('formField.CountryCode')}</b>
                                    <p>{approvalListDetails.countryCode}</p>
                                </div>
                            </Grid>
                        </Grid>
                        <Grid container columnSpacing={4}>
                            <Grid item xs={6}>
                                <div
                                    hidden={
                                        approvalListDetails.appInfoID === 3 ||
                                        approvalListDetails.entitlementRequestConfiguration[
                                            approvalListDetails.cornerstoneConfigurationConstants[
                                                'AdminHierarchy1Label'
                                            ].toString()
                                        ] === ''
                                    }
                                >
                                    <b>
                                        {
                                            approvalListDetails.entitlementRequestConfiguration[
                                                approvalListDetails.cornerstoneConfigurationConstants[
                                                    'AdminHierarchy1Label'
                                                ].toString()
                                            ]
                                        }
                                    </b>
                                    <p>{approvalListDetails.orgUnitLevel1Name}</p>
                                </div>
                                <div
                                    hidden={
                                        approvalListDetails.appInfoID === 3 ||
                                        approvalListDetails.entitlementRequestConfiguration[
                                            approvalListDetails.cornerstoneConfigurationConstants[
                                                'AdminHierarchy3Label'
                                            ].toString()
                                        ] === ''
                                    }
                                >
                                    <b>
                                        {
                                            approvalListDetails.entitlementRequestConfiguration[
                                                approvalListDetails.cornerstoneConfigurationConstants[
                                                    'AdminHierarchy3Label'
                                                ].toString()
                                            ]
                                        }
                                    </b>
                                    <p>{approvalListDetails.orgUnitLevel3Name}</p>
                                </div>
                                <div
                                    hidden={
                                        approvalListDetails.appInfoID === 3 ||
                                        approvalListDetails.entitlementRequestConfiguration[
                                            approvalListDetails.cornerstoneConfigurationConstants[
                                                'AdminHierarchy5Label'
                                            ].toString()
                                        ] === ''
                                    }
                                >
                                    <b>
                                        {
                                            approvalListDetails.entitlementRequestConfiguration[
                                                approvalListDetails.cornerstoneConfigurationConstants[
                                                    'AdminHierarchy5Label'
                                                ].toString()
                                            ]
                                        }
                                    </b>
                                    <p>{approvalListDetails.orgUnitLevel5Name}</p>
                                </div>
                            </Grid>
                            <Grid item xs={6}>
                                <div
                                    hidden={
                                        approvalListDetails.appInfoID === 3 ||
                                        approvalListDetails.entitlementRequestConfiguration[
                                            approvalListDetails.cornerstoneConfigurationConstants[
                                                'AdminHierarchy2Label'
                                            ].toString()
                                        ] === ''
                                    }
                                >
                                    <b>
                                        {
                                            approvalListDetails.entitlementRequestConfiguration[
                                                approvalListDetails.cornerstoneConfigurationConstants[
                                                    'AdminHierarchy2Label'
                                                ].toString()
                                            ]
                                        }
                                    </b>
                                    <p>{approvalListDetails.orgUnitLevel2Name}</p>
                                </div>
                                <div
                                    hidden={
                                        approvalListDetails.appInfoID === 3 ||
                                        approvalListDetails.entitlementRequestConfiguration[
                                            approvalListDetails.cornerstoneConfigurationConstants[
                                                'AdminHierarchy4Label'
                                            ].toString()
                                        ] === ''
                                    }
                                >
                                    <b>
                                        {
                                            approvalListDetails.entitlementRequestConfiguration[
                                                approvalListDetails.cornerstoneConfigurationConstants[
                                                    'AdminHierarchy4Label'
                                                ].toString()
                                            ]
                                        }
                                    </b>
                                    <p>{approvalListDetails.orgUnitLevel4Name}</p>
                                </div>
                            </Grid>
                        </Grid>
                        <Grid container columnSpacing={4}>
                            <Grid item xs={6}>
                                <div
                                    hidden={
                                        approvalListDetails.appInfoID === 3 ||
                                        approvalListDetails.entitlementRequestConfiguration[
                                            approvalListDetails.cornerstoneConfigurationConstants[
                                                'BusinessRelationshipIdentifiers'
                                            ].toString()
                                        ] === ''
                                    }
                                >
                                    <b>{t('formField.businessRelationship')}</b>
                                    <p>{approvalListDetails.businessRelationshipName}</p>
                                </div>
                                <div
                                    hidden={
                                        approvalListDetails.appInfoID === 3 ||
                                        approvalListDetails.entitlementRequestConfiguration[
                                            approvalListDetails.cornerstoneConfigurationConstants[
                                                'LocationDisabledId'
                                            ].toString()
                                        ] === 'Yes'
                                    }
                                >
                                    <b>{t('formField.PersonnelSubarea')}</b>
                                    <p>{approvalListDetails.locationName}</p>
                                </div>
                            </Grid>
                            <Grid item xs={6}>
                                <div
                                    hidden={
                                        approvalListDetails.appInfoID === 3 ||
                                        approvalListDetails.entitlementRequestConfiguration[
                                            approvalListDetails.cornerstoneConfigurationConstants[
                                                'ExternalPositionIdentifiers'
                                            ].toString()
                                        ] === ''
                                    }
                                >
                                    <b>{t('formField.LocalPosition')}</b>
                                    <p>{approvalListDetails.externalPositionName}</p>
                                </div>
                                <div hidden={approvalListDetails.appInfoID === 3}>
                                    <b>{t('formField.LegalEntity')}</b>
                                    <p>{approvalListDetails.legalEntityName}</p>
                                </div>
                            </Grid>
                        </Grid>
                        <Grid container columnSpacing={4}>
                            <Grid item xs={6}>
                                <div hidden={approvalListDetails.appInfoID === 3}>
                                    <b>{t('formField.lindeContactFirstName')}</b>
                                    <p>{approvalListDetails.lindeContactFirstName}</p>
                                </div>
                                <div hidden={approvalListDetails.appInfoID === 3}>
                                    <b>{t('formField.lindeContactEmail')}</b>
                                    <p>{approvalListDetails.lindeContactEmail}</p>
                                </div>
                            </Grid>
                            <Grid item xs={6}>
                                <div hidden={approvalListDetails.appInfoID === 3}>
                                    <b>{t('formField.lindeContactLastName')}</b>
                                    <p>{approvalListDetails.lindeContactLastName}</p>
                                </div>
                                <div hidden={approvalListDetails.appInfoID === 3}>
                                    <b>{t('formField.LindeManagerCPID')}</b>
                                    <p>{approvalListDetails.lindeManagerCPID}</p>
                                </div>
                            </Grid>
                        </Grid>
                        <Grid container columnSpacing={4}>
                            <div
                                hidden={
                                    approvalListDetails.appInfoID === 3 ||
                                    approvalListDetails.entitlementRequestConfiguration[
                                        approvalListDetails.cornerstoneConfigurationConstants[
                                            'UserHierarchy1Label'
                                        ].toString()
                                    ] === ''
                                }
                            >
                                <b>
                                    {
                                        approvalListDetails.entitlementRequestConfiguration[
                                            approvalListDetails.cornerstoneConfigurationConstants[
                                                'UserHierarchy1Label'
                                            ].toString()
                                        ]
                                    }
                                </b>
                                <p>{approvalListDetails.userSupportInfoHierarchy1Name}</p>
                            </div>
                            <div
                                hidden={
                                    approvalListDetails.appInfoID === 3 ||
                                    approvalListDetails.entitlementRequestConfiguration[
                                        approvalListDetails.cornerstoneConfigurationConstants[
                                            'UserHierarchy2Label'
                                        ].toString()
                                    ] === ''
                                }
                            >
                                <b>
                                    {
                                        approvalListDetails.entitlementRequestConfiguration[
                                            approvalListDetails.cornerstoneConfigurationConstants[
                                                'UserHierarchy2Label'
                                            ].toString()
                                        ]
                                    }
                                </b>
                                <p>{approvalListDetails.userSupportInfoHierarchy2Name}</p>
                            </div>
                            <div
                                hidden={
                                    approvalListDetails.appInfoID === 3 ||
                                    approvalListDetails.entitlementRequestConfiguration[
                                        approvalListDetails.cornerstoneConfigurationConstants[
                                            'UserHierarchy3Label'
                                        ].toString()
                                    ] === ''
                                }
                            >
                                <b>
                                    {
                                        approvalListDetails.entitlementRequestConfiguration[
                                            approvalListDetails.cornerstoneConfigurationConstants[
                                                'UserHierarchy3Label'
                                            ].toString()
                                        ]
                                    }
                                </b>
                                <p>{approvalListDetails.userSupportInfoHierarchy3Name}</p>
                            </div>
                        </Grid>
                        <Grid container columnSpacing={4}>
                            <Grid item xs={6}>
                                <div
                                    hidden={
                                        approvalListDetails.appInfoID === 3 ||
                                        approvalListDetails.entitlementRequestConfiguration[
                                            approvalListDetails.cornerstoneConfigurationConstants[
                                                'CornerstoneData1Label'
                                            ].toString()
                                        ] === ''
                                    }
                                >
                                    <b>
                                        {
                                            approvalListDetails.entitlementRequestConfiguration[
                                                approvalListDetails.cornerstoneConfigurationConstants[
                                                    'CornerstoneData1Label'
                                                ].toString()
                                            ]
                                        }
                                    </b>
                                    <p>{approvalListDetails.cornerstoneData1}</p>
                                </div>
                                <div
                                    hidden={
                                        approvalListDetails.appInfoID === 3 ||
                                        approvalListDetails.entitlementRequestConfiguration[
                                            approvalListDetails.cornerstoneConfigurationConstants[
                                                'CornerstoneData3Label'
                                            ].toString()
                                        ] === ''
                                    }
                                >
                                    <b>
                                        {
                                            approvalListDetails.entitlementRequestConfiguration[
                                                approvalListDetails.cornerstoneConfigurationConstants[
                                                    'CornerstoneData3Label'
                                                ].toString()
                                            ]
                                        }
                                    </b>
                                    <p>{approvalListDetails.cornerstoneData3}</p>
                                </div>
                            </Grid>
                            <Grid item xs={6}>
                                <div
                                    hidden={
                                        approvalListDetails.appInfoID === 3 ||
                                        approvalListDetails.entitlementRequestConfiguration[
                                            approvalListDetails.cornerstoneConfigurationConstants[
                                                'CornerstoneData2Label'
                                            ].toString()
                                        ] === ''
                                    }
                                >
                                    <b>
                                        {
                                            approvalListDetails.entitlementRequestConfiguration[
                                                approvalListDetails.cornerstoneConfigurationConstants[
                                                    'CornerstoneData2Label'
                                                ].toString()
                                            ]
                                        }
                                    </b>
                                    <p>{approvalListDetails.cornerstoneData2}</p>
                                </div>
                                <div
                                    hidden={
                                        approvalListDetails.appInfoID === 3 ||
                                        approvalListDetails.entitlementRequestConfiguration[
                                            approvalListDetails.cornerstoneConfigurationConstants[
                                                'CornerstoneData4Label'
                                            ].toString()
                                        ] === ''
                                    }
                                >
                                    <b>
                                        {
                                            approvalListDetails.entitlementRequestConfiguration[
                                                approvalListDetails.cornerstoneConfigurationConstants[
                                                    'CornerstoneData4Label'
                                                ].toString()
                                            ]
                                        }
                                    </b>
                                    <p>{approvalListDetails.cornerstoneData4}</p>
                                </div>
                            </Grid>
                        </Grid>
                        <Grid container columnSpacing={4}>
                            <Grid item xs={6}>
                                <div
                                    hidden={
                                        approvalListDetails.appInfoID === 3 ||
                                        approvalListDetails.entitlementRequestConfiguration[
                                            approvalListDetails.cornerstoneConfigurationConstants[
                                                'SupportInfo1Label'
                                            ].toString()
                                        ] === ''
                                    }
                                >
                                    <b>
                                        {
                                            approvalListDetails.entitlementRequestConfiguration[
                                                approvalListDetails.cornerstoneConfigurationConstants[
                                                    'SupportInfo1Label'
                                                ].toString()
                                            ]
                                        }
                                    </b>
                                    <p>{approvalListDetails.supportInfo1}</p>
                                </div>
                                <div
                                    hidden={
                                        approvalListDetails.appInfoID === 3 ||
                                        approvalListDetails.entitlementRequestConfiguration[
                                            approvalListDetails.cornerstoneConfigurationConstants[
                                                'SupportInfo2Label'
                                            ].toString()
                                        ] === ''
                                    }
                                >
                                    <b>
                                        {
                                            approvalListDetails.entitlementRequestConfiguration[
                                                approvalListDetails.cornerstoneConfigurationConstants[
                                                    'SupportInfo2Label'
                                                ].toString()
                                            ]
                                        }
                                    </b>
                                    <p>{approvalListDetails.supportInfo2}</p>
                                </div>
                                <div
                                    hidden={
                                        approvalListDetails.appInfoID === 3 ||
                                        approvalListDetails.entitlementRequestConfiguration[
                                            approvalListDetails.cornerstoneConfigurationConstants[
                                                'SupportInfo3Label'
                                            ].toString()
                                        ] === ''
                                    }
                                >
                                    <b>
                                        {
                                            approvalListDetails.entitlementRequestConfiguration[
                                                approvalListDetails.cornerstoneConfigurationConstants[
                                                    'SupportInfo3Label'
                                                ].toString()
                                            ]
                                        }
                                    </b>
                                    <p>{approvalListDetails.supportInfo3}</p>
                                </div>
                            </Grid>
                        </Grid>
                        <Grid container columnSpacing={4}>
                            <Grid item xs={6}>
                                <div
                                    hidden={
                                        approvalListDetails.appInfoID != 3 &&
                                        approvalListDetails.appInfoID != 8
                                    }
                                >
                                    <b>{t('formField.Location')}</b>
                                    <p>{approvalListDetails.intelexLocationObject?.name}</p>
                                </div>
                                <div
                                    hidden={
                                        approvalListDetails.appInfoID != 3 &&
                                        approvalListDetails.appInfoID != 8
                                    }
                                >
                                    <b>{t('formField.SupervisorNumber')}</b>
                                    <p>{approvalListDetails.supervisorDisplayName}</p>
                                </div>
                            </Grid>
                            <Grid item xs={6}>
                                <div
                                    hidden={
                                        approvalListDetails.appInfoID != 3 &&
                                        approvalListDetails.appInfoID != 8
                                    }
                                >
                                    <b>{t('formField.PositionTitle')}</b>
                                    <p>{approvalListDetails.positionTitle}</p>
                                </div>
                                <div
                                    hidden={
                                        approvalListDetails.appInfoID != 3 &&
                                        approvalListDetails.appInfoID != 8
                                    }
                                >
                                    <b>{t('formField.myCompany')}</b>
                                    <p>{approvalListDetails.company}</p>
                                </div>
                            </Grid>
                        </Grid>
                        <Stack direction="row" spacing={2}>
                            {approvalListDetails.userObjectID === null ? (
                                <Button
                                    variant="outlined"
                                    startIcon={<ReplayIcon />}
                                    onClick={() => handleReprocess(approvalListDetails.id)}
                                >
                                    Reprocess
                                </Button>
                            ) : null}
                            <Button
                                variant="contained"
                                startIcon={<PlaylistRemoveIcon />}
                                disabled={[1, 3, 6].includes(approvalListDetails.statusId || 0)}
                                onClick={() => rejectRequest(state.user, approvalListDetails.id)}
                            >
                                {t('formField.RejectRequest')}
                            </Button>

                            <Button
                                variant="contained"
                                startIcon={<Flip />}
                                onClick={() => setModalOpen(true)}
                                disabled={approvalListDetails.statusName === 'In conversion'}
                            >
                                Switch to Online user
                            </Button>
                        </Stack>
                    </DrawerBody>
                )}
            </Drawer>
        </>
    );
};

export default OfflineUsers;
