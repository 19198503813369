import { useContext, useEffect, useState } from 'react';
import { UserContext } from '../../context';
import {
    Avatar,
    Box,
    CircularProgress,
    Grid,
    Input,
    InputLabel,
    Tab,
    Tabs,
    Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import elevateImg from '../../assets/elevate.png';
import lidapImg from '../../assets/lidap.png';
import linxImg from '../../assets/linxCard.png';
import { api } from '../../services';
import { generateToken } from '../../services/fetch-api/auth/authToken';
import { useMsal } from '@azure/msal-react';
import { useTranslation } from 'react-i18next';
import LinearProgress from '@mui/material/LinearProgress';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import DeleteIcon from '@mui/icons-material/Delete';
import Stack from '@mui/material/Stack';
import AppInsights from './AppInsights';
import SuperAdminManagement from './SuperAdminManagement';
import Dashboard from './Dashboard';
import { TabPanelProps } from '../../types';
import { InputContainer } from '../../styled';
import { use } from 'i18next';
import { Buffer } from 'buffer';

const modalStyle = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    borderRadius: '12px',
    boxShadow: 24,
    p: 3,
    outline: 'none',
};

const LinxCard = styled('div')(({ theme }) => ({
    width: '200px',
    height: '100px',
    boxShadow: '5px 5px 12px -6px rgba(0, 0, 0, 1)',
    padding: '20px',
    marginLeft: '20px',
    cursor: 'pointer',
    borderRadius: '8px',
    backgroundImage: `url(${linxImg})`,
    backgroundPosition: '50%',
    backgroundSize: 'cover',
    '&:hover': {
        opacity: '90%',
    },
    position: 'relative',
}));

const ElevateCard = styled('div')(({ theme }) => ({
    width: '200px',
    height: '100px',
    backgroundColor: 'white',
    boxShadow: '5px 5px 12px -6px rgba(0, 0, 0, 1)',
    padding: '20px',
    marginLeft: '20px',
    cursor: 'pointer',
    borderRadius: '8px',
    backgroundImage: `url(${elevateImg})`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    '&:hover': {
        opacity: '90%',
        '& $OptionsButton': {
            background: 'green',
        },
    },
    position: 'relative',
    zIndex: '0',
}));

const OptionsButton = styled('div')(({ theme }) => ({
    width: '20px',
    height: '20px',
    boxShadow: '5px 5px 12px -6px rgba(0, 0, 0, 1)',
    cursor: 'pointer',
    borderRadius: '50%',
    backgroundColor: `white`,
    position: 'absolute',
    right: '-8px',
    top: '-8px',
    display: 'none',
    zIndex: '7',
}));

const ExternalUserInfoMessage = styled('h4')(() => ({
    color: '#6e6e6e',
    marginLeft: '8px',
}));

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const Home = () => {
    const { state, dispatch } = useContext(UserContext);
    const navigate = useNavigate();
    const { instance, accounts } = useMsal();
    const [entitlements, setEntitlements] = useState<any>([]);
    const [appInsights, setAppInsights] = useState<any>([]);
    const [appExceptions, setAppExceptions] = useState<any>([]);
    const { t } = useTranslation();
    const [isLoading, setIsLoading] = useState(false);
    const [insightLoading, setInsightLoading] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    const [value, setValue] = useState(0);
    const [userInfo, setUserInfo] = useState<any>(null);
    const [newDataLoading, setNewDataLoading] = useState(false);

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    const handleOpenModal = () => setOpenModal(true);
    const handleCloseModal = () => setOpenModal(false);

    function redirectIfOneBS(id: number) {
        if (state.user?.groups.length === 1) {
            dispatch({
                type: 'SET_APPID',
                data: id,
            });
            navigate('/approval-list');
        }
    }

    if (state.user?.groups !== undefined) {
        if (state.user?.groups.length === 1) {
            if (
                state.user?.groups[0] === 'LEIP Elevate Business Administrators' ||
                state.user?.groups[0] === 'e0f7f12f-9f9b-41c0-9d97-f4657028d7dd' ||
                state.user?.groups[0] === '7b51cad9-9635-418e-834b-ad8d137d9099'
            ) {
                redirectIfOneBS(1);
            } else if (
                state.user?.groups[0] === 'LEIP LiDAP Business Administrators' ||
                state.user?.groups[0] === '2a56c64c-8f8b-49d0-b3bc-418d35512740' ||
                state.user?.groups[0] === '7b51cad9-9635-418e-834b-ad8d137d9099'
            ) {
                redirectIfOneBS(3);
            }
        }
    }

    const navigateUserApp = (url: string, id: number) => {
        dispatch({
            type: 'SET_APPID',
            data: id,
        });
        window.location.replace(url);
    };

    useEffect(() => {
        if (state.user) {
            api.entitlement
                .getUserEntitlement(state.user, generateToken(instance, accounts))
                .then((res: any) => {
                    if (!res) {
                        return;
                    }
                    setEntitlements(res);
                });
        }

        if (state.user?.role === 'SuperAdministrator' && state.appId === 1) {
            setInsightLoading(true);
            api.entitlement
                .getAppInsightTrace(state.user, 1, 2000, generateToken(instance, accounts))
                .then((res: any) => {
                    if (!res) {
                        setInsightLoading(false);
                        return;
                    }
                    setAppInsights(res);
                    setInsightLoading(false);
                });
        }

        if (state.user?.role === 'SuperAdministrator' && state.appId === 1) {
            api.entitlement
                .getAppExceptions(state.user, 1, 2000, generateToken(instance, accounts))
                .then((res: any) => {
                    if (!res) {
                        setInsightLoading(false);
                        return;
                    }
                    setAppExceptions(res);
                    setInsightLoading(false);
                });
        }
    }, [state.user, state.appId, state.linxRoles]);

    useEffect(() => {
        state.user &&
            api.entitlement
                .getUserInformation(state.user, generateToken(instance, accounts), {
                    firstName: state.user.firstName,
                    lastName: state.user.lastName,
                    email: state.user.email,
                    userObjectId: state.user.id,
                    id: null,
                })
                .then((res: any) => {
                    setUserInfo(res);
                })
                .catch((error: any) => {
                    console.log(error);
                });

        if (state.user && state.user.firstName) {
            console.log('state.user', state.user);
            const hash = window.location.hash.replace('#id_token=', '');

            try {
                const base64Payload = hash.split('.')[1];
                if (!base64Payload) {
                    throw new Error('JWT payload is missing');
                }
                const decodedPayload = Buffer.from(base64Payload, 'base64').toString('utf-8');
                const idTokenClaims = JSON.parse(decodedPayload);
                if (
                    idTokenClaims &&
                    idTokenClaims.acr === 'b2c_1a_v1_linde_portal_user_profileedit'
                ) {
                    setNewDataLoading(true);
                    state.user &&
                        api.adHocInvite
                            .getUserDetailedInfo2(state.user, generateToken(instance, accounts), {
                                firstName: idTokenClaims.first_name,
                                lastName: idTokenClaims.family_name,
                                email: idTokenClaims.mail,
                                userObjectId: idTokenClaims.sub,
                                id: null,
                            })
                            .then((response: any) => {
                                let modifiedFormValues = { ...response };
                                modifiedFormValues.firstName = idTokenClaims.givenname;
                                modifiedFormValues.lastName = idTokenClaims.surname;

                                api.adHocInvite
                                    .updateUserObject(
                                        state.user,
                                        modifiedFormValues,
                                        generateToken(instance, accounts),
                                    )
                                    .then((res) => {
                                        !res.data && setNewDataLoading(false);
                                        res.data &&
                                            api.adHocInvite
                                                .updateCornerstoneUserFromEntitlementRequest(
                                                    state.user,
                                                    res.data,
                                                    generateToken(instance, accounts),
                                                )
                                                .then(() => {
                                                    state.user &&
                                                        api.entitlement
                                                            .getUserInformation(
                                                                state.user,
                                                                generateToken(instance, accounts),
                                                                {
                                                                    firstName: state.user.firstName,
                                                                    lastName: state.user.lastName,
                                                                    email: state.user.email,
                                                                    userObjectId: state.user.id,
                                                                    id: null,
                                                                },
                                                            )
                                                            .then((res: any) => {
                                                                setUserInfo(res);
                                                                setNewDataLoading(false);
                                                            })
                                                            .catch((error: any) => {
                                                                console.log(error);
                                                            });
                                                });
                                    });
                            });
                } else if (
                    idTokenClaims &&
                    idTokenClaims.acr === 'b2c_1a_v1_linde_portal_user_change_signinemail'
                ) {
                    setNewDataLoading(true);
                    state.user &&
                        api.adHocInvite
                            .getUserDetailedInfo2(state.user, generateToken(instance, accounts), {
                                firstName: idTokenClaims.first_name,
                                lastName: idTokenClaims.family_name,
                                email: idTokenClaims.mail,
                                userObjectId: idTokenClaims.sub,
                                id: null,
                            })
                            .then((response: any) => {
                                let modifiedFormValues = { ...response };
                                modifiedFormValues.email = idTokenClaims.mail;

                                api.adHocInvite
                                    .updateUserObject(
                                        state.user,
                                        modifiedFormValues,
                                        generateToken(instance, accounts),
                                    )
                                    .then((res) => {
                                        !res.data && setNewDataLoading(false);
                                        res.data &&
                                            api.adHocInvite
                                                .updateCornerstoneUserFromEntitlementRequest(
                                                    state.user,
                                                    res.data,
                                                    generateToken(instance, accounts),
                                                )
                                                .then(() => {
                                                    state.user &&
                                                        api.entitlement
                                                            .getUserInformation(
                                                                state.user,
                                                                generateToken(instance, accounts),
                                                                {
                                                                    firstName: state.user.firstName,
                                                                    lastName: state.user.lastName,
                                                                    email: state.user.email,
                                                                    userObjectId: state.user.id,
                                                                    id: null,
                                                                },
                                                            )
                                                            .then((res: any) => {
                                                                setUserInfo(res);
                                                                setNewDataLoading(false);
                                                            })
                                                            .catch((error: any) => {
                                                                console.log(error);
                                                            });
                                                });
                                    });
                            });
                }
            } catch (error) {
                console.error('Error parsing idTokenClaims:', error);
            }
        }
    }, [state.user]);

    const genrateEmailChangeLink = () => {
        api.entitlement
            .generateCoreDataChangeLink(
                state.user,
                'B2C_1A_V1_LINDE_PORTAL_USER_CHANGE_SIGNINEMAIL',
                generateToken(instance, accounts),
            )
            .then((res: any) => {
                console.log(res);
                window.open(res.link, '_self');
            })
            .catch((error: any) => {
                console.log(error);
            });
    };

    const genrateNameChangeLink = () => {
        api.entitlement
            .generateCoreDataChangeLink(
                state.user,
                'B2C_1A_V1_LINDE_PORTAL_USER_PROFILEEDIT',
                generateToken(instance, accounts),
            )
            .then((res: any) => {
                console.log(res);
                window.open(res.link, '_self');
            })
            .catch((error: any) => {
                console.log(error);
            });
    };

    return (
        <>
            {entitlements &&
            entitlements.length > 0 &&
            entitlements[0].name !== 'Lidap' &&
            state.user.role !== 'SuperAdministrator' ? (
                <>
                    <ExternalUserInfoMessage>
                        {t('label.welcome')} {state.user.firstName}, {t('label.youCanAccess')}
                    </ExternalUserInfoMessage>
                    <Grid container alignItems="center" columnSpacing={2} sx={{ marginBottom: 3 }}>
                        <>
                            {entitlements.map((data: any, index: number) => {
                                if (data.id === 3) {
                                } else if (data.id === 1) {
                                    return (
                                        <ElevateCard
                                            onClick={(event) =>
                                                event.currentTarget == event.target &&
                                                navigateUserApp(data.appUrl, data.id)
                                            }
                                            sx={{
                                                ':hover .optionsButton': {
                                                    display: 'block',
                                                },
                                            }}
                                        >
                                            <OptionsButton
                                                onClick={handleOpenModal}
                                                className="optionsButton"
                                            >
                                                {' '}
                                                <RemoveCircleIcon
                                                    sx={{
                                                        width: '20px',
                                                        height: '20px',
                                                        color: 'red',
                                                    }}
                                                />{' '}
                                            </OptionsButton>
                                        </ElevateCard>
                                    );
                                } else if (data.id === Number(process.env.REACT_APP_LINX_ID)) {
                                    return (
                                        <LinxCard
                                            onClick={(event) =>
                                                event.currentTarget == event.target &&
                                                navigateUserApp(data.appUrl, data.id)
                                            }
                                            sx={{
                                                ':hover .optionsButton': {
                                                    display: 'block',
                                                },
                                            }}
                                        >
                                            <OptionsButton
                                                onClick={handleOpenModal}
                                                className="optionsButton"
                                            >
                                                {' '}
                                                <RemoveCircleIcon
                                                    sx={{
                                                        width: '20px',
                                                        height: '20px',
                                                        color: 'red',
                                                    }}
                                                />{' '}
                                            </OptionsButton>
                                        </LinxCard>
                                    );
                                }
                            })}
                        </>
                    </Grid>

                    <Modal open={openModal} onClose={handleCloseModal}>
                        <Box sx={modalStyle}>
                            <p style={{ textAlign: 'center' }}>{t('modal.RemoveEntitlement')}</p>
                            <Stack sx={{ justifyContent: 'center' }} direction="row" spacing={2}>
                                <Button variant="outlined" startIcon={<DeleteIcon />}>
                                    {t('yes')}
                                </Button>
                                <Button onClick={() => setOpenModal(false)} variant="contained">
                                    {t('formField.cancel')}
                                </Button>
                            </Stack>
                        </Box>
                    </Modal>
                </>
            ) : state.user &&
              state.user.firstName &&
              state.user.role !== 'Administrator' &&
              state.user.role !== 'SuperAdministrator' &&
              entitlements &&
              entitlements.length === 0 ? (
                <p
                    style={{
                        color: '#1976d2',
                        fontSize: '18px',
                        fontWeight: '700',
                        textAlign: 'center',
                        marginTop: '70px',
                    }}
                >
                    No applications currently assigned
                </p>
            ) : (
                state.user?.role === 'SuperAdministrator' &&
                state.appId !== Number(process.env.REACT_APP_LINX_ID) && (
                    <Box sx={{ width: '100%' }}>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <Tabs
                                value={value}
                                onChange={handleChange}
                                aria-label="basic tabs example"
                            >
                                <Tab label={'Dashboard'} {...a11yProps(0)} />
                                <Tab label={t('label.AppInsights')} {...a11yProps(1)} />
                                <Tab label={t('label.AppExceptions')} {...a11yProps(2)} />
                                <Tab label={t('label.superAdminManagement')} {...a11yProps(3)} />
                            </Tabs>
                        </Box>
                        <TabPanel value={value} index={0}>
                            <Dashboard />
                        </TabPanel>
                        <TabPanel value={value} index={1}>
                            <AppInsights data={appInsights.appInsight} loading={insightLoading} />
                        </TabPanel>
                        <TabPanel value={value} index={2}>
                            <AppInsights data={appExceptions.appInsight} loading={insightLoading} />
                        </TabPanel>
                        <TabPanel value={value} index={3}>
                            <SuperAdminManagement />
                        </TabPanel>
                    </Box>
                )
            )}
            {state.user && state.user.firstName && userInfo && (
                <>
                    <div style={{ width: '100%', height: '1px', backgroundColor: 'black' }}></div>

                    <p>Manage your data</p>

                    {newDataLoading ? (
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                height: '50vh',
                            }}
                        >
                            <CircularProgress />
                        </div>
                    ) : (
                        <div style={{ display: 'flex' }}>
                            <div style={{ width: '30%' }}>
                                <InputContainer>
                                    <InputLabel htmlFor="user-name">
                                        {t('formField.firstName')}
                                    </InputLabel>
                                    <Input
                                        fullWidth
                                        id="user-name"
                                        name="name"
                                        value={userInfo.firstName}
                                        disabled
                                    />
                                </InputContainer>

                                <InputContainer>
                                    <InputLabel htmlFor="user-lastName">
                                        {t('formField.lastName')}
                                    </InputLabel>
                                    <Input
                                        fullWidth
                                        id="user-lastName"
                                        name="lastName"
                                        value={userInfo.lastName}
                                        disabled
                                    />
                                </InputContainer>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={genrateNameChangeLink}
                                >
                                    Change First and Last Name
                                </Button>
                            </div>

                            <div style={{ width: '30%', marginLeft: '60px' }}>
                                <InputContainer>
                                    <InputLabel htmlFor="user-email">
                                        {t('formField.email')}
                                    </InputLabel>
                                    <Input
                                        fullWidth
                                        id="user-email"
                                        name="email"
                                        value={userInfo.email}
                                        disabled
                                    />
                                </InputContainer>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={genrateEmailChangeLink}
                                >
                                    Change Email
                                </Button>
                            </div>
                        </div>
                    )}
                </>
            )}
        </>
    );
};

export default Home;
