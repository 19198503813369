import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
    Autocomplete,
    Box,
    Grid,
    Input,
    InputLabel,
    LinearProgress,
    styled,
    TextField,
} from '@mui/material';
import { useDebouncedCallback } from '../../hooks/useDebouncedCallback';
import { InputContainer, ManagersData } from '../../styled';
import type { IAutocomplete } from '../../types';
import { LindeManager } from '../../types/lindeManagers';
import { SearchManagerForm, useGetLindeManagers } from './hooks/useGetLindeManagers';

const Error = styled('span')(() => ({
    color: 'red',
    fontSize: '10px',
    margin: 0,
    marginLeft: '5px',
    marginBottom: '2px',
}));

function setLindeManagerFormInitialState(): SearchManagerForm {
    return {
        lastName: '',
        firstName: '',
        email: '',
        user_ref: '',
        cornerstoneID: '',
    };
}

interface AdHocManagersFieldsProps {
    defaultManager?: LindeManager;
    requiredMessage: boolean;
    mandatory?: boolean;
    handleAutocompleteChange: (value: string | IAutocomplete | null, name: string) => void;
    mandatoryOnSubmit: boolean;
}

const AdHocManagersFields = (props: AdHocManagersFieldsProps) => {
    const [open, setOpen] = useState(false);
    const { t } = useTranslation();
    const { getLindeManagers, lindeManagers = [], isLindeManagersLoading } = useGetLindeManagers();
    const formik = useFormik<SearchManagerForm>({
        initialValues: setLindeManagerFormInitialState(),
        onSubmit: (values) => {
            getLindeManagers(values, () => setOpen(true));
        },
    });
    const submitFormDebounced = useDebouncedCallback(formik.submitForm, 300);

    useEffect(() => {
        if (formik.dirty) {
            submitFormDebounced();
        }
    }, [formik.values, formik.dirty, submitFormDebounced]);

    return (
        <Box sx={{ width: 'calc(100vh-30px)' }}>
            <ManagersData>
                <legend>{t('label.searchManagers')}</legend>
                <Grid container columnSpacing={6} sx={{ marginTop: '15px' }}>
                    <Grid item xs={6} sx={{ marginBottom: 'px' }}>
                        <InputContainer>
                            <InputLabel htmlFor="user-name">{t('formField.firstName')}</InputLabel>
                            <Input
                                fullWidth
                                id="user-name"
                                name="firstName"
                                onChange={formik.handleChange}
                            />
                        </InputContainer>
                    </Grid>
                    <Grid item xs={6} sx={{ position: 'relative' }}>
                        {isLindeManagersLoading && (
                            <LinearProgress
                                sx={{
                                    position: 'absolute',
                                    left: 48,
                                    right: 0,
                                    bottom: 10,
                                }}
                            />
                        )}
                        <InputContainer>
                            <InputLabel htmlFor="country-code">
                                {t('label.searchResults')}
                                {props.mandatory && <span style={{ color: 'red' }}> *</span>}
                                {props.mandatoryOnSubmit && (
                                    <Error className="errorMsgs">
                                        {t('errorMessage.RequiredField')}
                                    </Error>
                                )}
                            </InputLabel>
                            <Autocomplete
                                disablePortal
                                id="combo-box-demo"
                                open={open}
                                onOpen={() => setOpen(true)}
                                onClose={() => setOpen(false)}
                                options={lindeManagers}
                                sx={{ width: '100%' }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        variant="standard"
                                        label={
                                            props.defaultManager
                                                ? props.defaultManager.user_name_first +
                                                  ' ' +
                                                  props.defaultManager.user_name_last +
                                                  ' ' +
                                                  '(' +
                                                  props.defaultManager.user_email +
                                                  ')'
                                                : ''
                                        }
                                    />
                                )}
                                onChange={(_, value: any) => {
                                    if (value) {
                                        props.handleAutocompleteChange(
                                            value.externalId,
                                            'lindeManagerCPIDExternal',
                                        );
                                        props.handleAutocompleteChange(
                                            value.id,
                                            'lindeManagerCPIDInternal',
                                        );
                                    } else {
                                        // Clear the values when the field is cleared
                                        props.handleAutocompleteChange(
                                            '',
                                            'lindeManagerCPIDExternal',
                                        );
                                        props.handleAutocompleteChange(
                                            '',
                                            'lindeManagerCPIDInternal',
                                        );
                                    }
                                }}
                            />
                        </InputContainer>
                    </Grid>
                </Grid>
                <Grid container columnSpacing={6} sx={{ marginTop: '15px' }}>
                    <Grid item xs={6}>
                        <InputContainer>
                            <InputLabel htmlFor="user-last-name">
                                {t('formField.lastName')}
                            </InputLabel>
                            <Input
                                fullWidth
                                id="user-last-name"
                                name="lastName"
                                onChange={formik.handleChange}
                            />
                        </InputContainer>
                    </Grid>
                </Grid>

                <Grid container columnSpacing={6} sx={{ marginTop: '15px' }}>
                    <Grid item xs={6} sx={{ marginBottom: 'px' }}>
                        <InputContainer>
                            <InputLabel htmlFor="user-email">{t('formField.email')}</InputLabel>
                            <Input
                                fullWidth
                                id="user-email"
                                name="email"
                                onChange={formik.handleChange}
                            />
                        </InputContainer>
                    </Grid>
                </Grid>

                <Grid container columnSpacing={6} sx={{ marginTop: '15px' }}>
                    <Grid item xs={6}>
                        <InputContainer>
                            <InputLabel htmlFor="user-ref">CPID / B2C ObjectId</InputLabel>
                            <Input
                                fullWidth
                                id="user-ref"
                                name="user_ref"
                                onChange={formik.handleChange}
                            />
                        </InputContainer>
                    </Grid>
                </Grid>

                <Grid container columnSpacing={6} sx={{ marginTop: '15px' }}>
                    <Grid item xs={6} sx={{ marginBottom: 'px' }}>
                        <InputContainer>
                            <InputLabel htmlFor="user-id">Linde User ID</InputLabel>
                            <Input
                                fullWidth
                                id="user-id"
                                name="cornerstoneID"
                                onChange={formik.handleChange}
                            />
                        </InputContainer>
                    </Grid>
                </Grid>
            </ManagersData>
        </Box>
    );
};

export default AdHocManagersFields;
