import { Route, Routes } from 'react-router-dom';
import './App.css';
import Layout from './components/layout';
import ApprovalList from './pages/approval-list';
import Home from './pages/home';
import { User } from './types';
import { AccountInfo } from '@azure/msal-browser';
import { useMsal } from '@azure/msal-react';
import { UserContext } from './context';
import { useContext, useState, useEffect, useLayoutEffect } from 'react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import UserDetail from './pages/user-detail';
import './i18n';
import { useTranslation } from 'react-i18next';
import { getLocaleKey } from './helpers';
import Audit from './pages/audit';
import AdHocInvites from './pages/adhoc-invites';
import UserActivity from './pages/user-activity';
import { api } from './services';
import { generateToken } from './services/fetch-api/auth/authToken';
import { useNavigate } from 'react-router-dom';
import BusinessSegments from './pages/business-segment';
import Plants from './pages/linx/plants';
import Contacts from './pages/linx/contacts';
import Companies from './pages/linx/companies';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import BusinessEvents from './pages/linx/business-events';
import AuthError from './pages/auth-error';
import Position from './pages/positions';
import ChangeUserData from './pages/change-user-data';
import { AppIdEnum } from './types/app';
import { Buffer } from 'buffer';

const allowedAcr = [
    'b2c_1a_v1_linde_cornerstone_signup_email_invite',
    'b2c_1a_v1_linde_cornerstone_signup_phone_invite',
    'b2c_1a_v1_linde_cornerstone_signup_email',
    'b2c_1a_v1_linde_cornerstone_signup_phone',
    'b2c_1a_v1_linde_intelex_signup_email_invite',
    'b2c_1a_v1_linde_intelex_signup_phone_invite',
    'b2c_1a_v1_linde_intelex_signup_phone',
    'b2c_1a_v1_linde_intelex_signup_email',
    'b2c_1a_v1_linde_portal_signup_email',
    'b2c_1a_v1_linde_portal_signup_phone',
    'b2c_1a_v1_linde_portal_user_change_phonenumber',
    'b2c_1a_v1_linde_portal_user_change_signinemail',
    'b2c_1a_v1_linde_linx_signup_email_invite',
    'b2c_1a_v1_linde_portal_user_profileedit',
];

const redTheme = createTheme({ palette: { primary: { main: '#990000' } } });
const blueTheme = createTheme({ palette: { primary: { main: '#0677d1' } } });

const App = () => {
    const { instance, accounts } = useMsal();
    const navigate = useNavigate();
    const { t, i18n } = useTranslation();
    const { state, dispatch } = useContext(UserContext);
    const [usersBusinessSegments, setUsersBusinessSegments] = useState<any[]>([]);
    const [selectedTheme, setSelectedTheme] = useState(blueTheme);
    const [userRole, setUserRole] = useState<string>('');

    const defaultBusinessSegment = {
        id: 1,
        internalId: 1,
        externalId: null,
        name: null,
        defaultLanguage: null,
        defaultTimeZone: null,
        intelexRequired: null,
        countryCode: null,
        isActive: null,
        appConfiguration: null,
    };

    useEffect(() => {
        let idTokenClaimsData = null;
        if (
            !accounts[0]?.idTokenClaims &&
            window.location.hash &&
            window.location.hash.includes('#id_token')
        ) {
            const hash = window.location.hash.replace('#id_token=', '');
            const idTokenClaims = hash
                ? JSON.parse(Buffer.from(hash.split('.')[1], 'base64').toString('utf-8'))
                : '';

            if (allowedAcr.includes(idTokenClaims?.acr)) {
                const emailHint: string =
                    idTokenClaims && idTokenClaims.email ? idTokenClaims.email : idTokenClaims.mail;
                const phoneHint: string =
                    idTokenClaims && idTokenClaims.phone ? idTokenClaims.phone : '';
                const ssoSilentRequest = {
                    scopes: ['openid', 'profile'],
                    redirectUri: '/edit-details',
                    loginHint: emailHint ? emailHint : phoneHint,
                };
                idTokenClaimsData = {
                    applicationId: idTokenClaims.applicationid,
                    entitlementRequestId: idTokenClaims.entitlementRequestId,
                };

                instance.ssoSilent(ssoSilentRequest).catch((e) => {
                    if (process.env.NODE_ENV === 'development') {
                        console.error(
                            'Problem using SSO for the user, will redirect to login page ',
                            e,
                        );
                    }
                    const error = JSON.stringify(e);
                    if (error.includes('AADB2C90077')) {
                        navigate('/auth-error');
                    }
                });
            }
        }

        if (instance && accounts) {
            let availableAccount: AccountInfo[] = accounts;
            let availableTokenClaims: any;
            let contextUser: User = {} as User;
            if (availableAccount && availableAccount.length > 0) {
                availableTokenClaims = availableAccount[0].idTokenClaims;
                let userMail = availableTokenClaims.mail
                    ? availableTokenClaims.mail
                    : availableTokenClaims.email
                      ? availableTokenClaims.email
                      : '';

                if (
                    userMail === 'milan.stefanovic@tron-it.com' &&
                    availableTokenClaims.groups != null
                ) {
                    availableTokenClaims.groups.push('32a5bae1-c8cb-4587-833e-1ed36afb4813'); // ZA BusinessAdministrator
                    availableTokenClaims.groups.push('d40307c2-5f08-430b-8301-a9b3c13d3254'); // SuperAdmin
                }

                let userIdentifierCS = availableTokenClaims.SAMAccountName
                    ? availableTokenClaims.SAMAccountName
                    : availableTokenClaims.sub;
                let userIdentifierLidap = availableTokenClaims.upn
                    ? availableTokenClaims.upn
                    : availableTokenClaims.sub;

                if (localStorage.getItem('i18nextLng') === null) {
                    localStorage.setItem('i18nextLng', getLocaleKey(navigator.language));
                }

                availableTokenClaims.groups.length > 0
                    ? api.entitlement
                          .handleUserRole(
                              generateToken(instance, accounts),
                              availableTokenClaims.groups,
                          )
                          .then((data: any) => {
                              setUserRole(data);
                          })
                          .catch((err) => {
                              console.log(err);
                          })
                    : setUserRole('ExternalUser');

                contextUser = {
                    id: availableTokenClaims.sub,
                    userIdCS: userIdentifierCS,
                    userIdLidap: userIdentifierLidap,
                    firstName: availableTokenClaims.first_name,
                    middleName: '',
                    lastName: availableTokenClaims.family_name,
                    email: userMail,
                    phone: availableTokenClaims.phone ? availableTokenClaims.phone : '', // should come from BE
                    groups: availableTokenClaims.groups
                        ? availableTokenClaims.groups.filter((x: string) => x !== null)
                        : [],
                    role: userRole,
                    termsOfConsent: availableTokenClaims.extension_termsOfUseConsentVersion,
                    language: localStorage.getItem('i18nextLng'),
                    SAMAccountName: userIdentifierCS,
                };
            }
            dispatch({
                type: 'SET_USER',
                data: { ...state.user, ...contextUser, ...idTokenClaimsData },
            });

            dispatch({
                type: 'SET_APPID',
                data: null,
            });

            if (idTokenClaimsData?.entitlementRequestId) {
                window.localStorage.entitlementRequestId = idTokenClaimsData?.entitlementRequestId;
            }

            if (idTokenClaimsData?.applicationId) {
                window.localStorage.applicationId = idTokenClaimsData?.applicationId;
            }
        }
    }, [instance, accounts, userRole]);

    useEffect(() => {
        i18n.changeLanguage(getLocaleKey(navigator.language));
    }, []);

    useEffect(() => {
        if ((state.appId === 3 || state.appId === 1) && state.user) {
            api.userDetails
                .getUserLocation(state.user, generateToken(instance, accounts))
                .then((res: any) => {
                    if (!res) return;
                    dispatch({
                        type: 'SET_LOCATION_CODE',
                        data: res.code,
                    });
                });
        }
    }, [state.user, state.appId]);

    useEffect(() => {
        if (!state.user || !state.appId) return;
        state.appId !== Number(process.env.REACT_APP_LINX_ID) &&
            state.user &&
            // (state.user.role === "Administrator" ||
            //     state.user.role === "SuperAdministrator") &&
            api.entitlement
                .getBusinessSegments(state.user, state.appId, generateToken(instance, accounts))
                .then((res: any) => {
                    console.log('EO ME U SEGMENT', res);
                    if (!res) return;
                    setUsersBusinessSegments(res.businessSegments);
                    if (state.appId !== 3) {
                        dispatch({
                            type: 'SET_BUSINESS_SEGMENT',
                            data: res,
                        });
                    }
                });
    }, [state.user, state.appId]);

    useEffect(() => {
        if (!state.user || !state.appId) return;
        state.appId === Number(process.env.REACT_APP_LINX_ID) &&
            state.user &&
            api.linx
                .getRegionsAndCountries(
                    state.user,
                    generateToken(instance, accounts),
                    state.user.groups,
                )
                .then((res: any) => {
                    dispatch({
                        type: 'SET_LINX_REGIONS_AND_COUNTRIES',
                        data: res,
                    });
                    dispatch({
                        type: 'SET_LINX_REGIONS',
                        data: res.map((data: any) => {
                            return { value: data.id, label: data.code };
                        }),
                    });
                    dispatch({
                        type: 'SET_LINX_COUNTRIES',
                        data: res.map((data: any) =>
                            data.linxBusinesses.map((country: any) => {
                                return country;
                            }),
                        ),
                    });
                });
    }, [state.user, state.appId, state.language]);

    let linxCountry: any = null;
    useEffect(() => {
        if (state.linxCountries.length > 0) {
            localStorage.getItem('linxCountry') !== null
                ? (linxCountry = JSON.parse(localStorage.getItem('linxCountry') || ''))
                : (linxCountry = {
                      value: state.linxCountries[0][0].id,
                      label: state.linxCountries[0][0].name,
                  });
            if (linxCountry !== null) {
                dispatch({
                    type: 'SET_CHOSEN_LINX_COUNTRY',
                    data: linxCountry,
                });
            } else if (linxCountry !== null && state.linxCountries.length > 0) {
                dispatch({
                    type: 'SET_CHOSEN_LINX_COUNTRY',
                    data: {
                        value: state.linxCountries[0][0].id,
                        label: state.linxCountries[0][0].name,
                    },
                });
                localStorage.setItem(
                    'linxCountry',
                    JSON.stringify({
                        value: state.linxCountries[0][0].id,
                        label: state.linxCountries[0][0].name,
                    }),
                );
            }
        }
    }, [state.appId, state.user, state.linxCountries]);

    useEffect(() => {
        if (!state.user) return;
        if (state.appId === 3) {
            dispatch({
                type: 'SET_CHOSEN_BUSINESS_SEGMENT',
                data: defaultBusinessSegment,
            });
        } else if (
            state.appId === 1 &&
            state.appId !== Number(process.env.REACT_APP_LINX_ID) &&
            (state.user.role === 'Administrator' || state.user.role === 'SuperAdministrator')
        ) {
            api.userDetails
                .getUsersLastBusinessSegment(state.user, generateToken(instance, accounts))
                ?.then((res: any) => {
                    if (!res) return;
                    if (res.internalId === -1) {
                        dispatch({
                            type: 'SET_CHOSEN_BUSINESS_SEGMENT',
                            data: usersBusinessSegments[0],
                        });
                        dispatch({
                            type: 'SET_APPID',
                            data: 1,
                        });
                        return;
                    } else if (state.appId === 1) {
                        dispatch({
                            type: 'SET_APPID',
                            data: 1,
                        });
                    }
                    dispatch({
                        type: 'SET_CHOSEN_BUSINESS_SEGMENT',
                        data: res,
                    });
                });
        }
    }, [state.user, state.businessSegment, state.appId]);

    useEffect(() => {
        state.user &&
            state.chosenLinxCountry &&
            api.linx
                .getLinxRoles(
                    state.user,
                    state.chosenLinxCountry.value,
                    state.user.groups,
                    generateToken(instance, accounts),
                )
                .then((res: any) => {
                    dispatch({
                        type: 'SET_LINX_ROLES',
                        data: res.data,
                    });
                });
    }, [state.appId, state.chosenLinxCountry]);

    // console.log(state.linxRoles);

    useLayoutEffect(() => {
        setSelectedTheme(
            state.chosenLinxCountryObject && state.chosenLinxCountryObject.themeColour === '#E1000F'
                ? redTheme
                : blueTheme,
        );
    }, [state.chosenLinxCountryObject]);

    console.log('User:', state.user);
    console.log('STATE', state);
    console.log(generateToken(instance, accounts));

    return (
        <ThemeProvider theme={selectedTheme}>
            <Layout>
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route
                        path="approval-list"
                        element={
                            state.user &&
                            state.user?.firstName &&
                            (state.user.role === 'Administrator' ||
                                state.user.role === 'SuperAdministrator') &&
                            (state.appId === 1 || state.appId === 3) ? (
                                <ApprovalList />
                            ) : (
                                <Home />
                            )
                        }
                    />
                    <Route path="edit-details" element={<UserDetail />} />
                    <Route
                        path="audit"
                        element={
                            state.user &&
                            state.user?.firstName &&
                            (state.user.role === 'Administrator' ||
                                state.user.role === 'SuperAdministrator') &&
                            (state.appId === 1 || state.appId === 3) ? (
                                <Audit />
                            ) : (
                                <Home />
                            )
                        }
                    />
                    <Route
                        path="adhoc-invites"
                        element={
                            state.user &&
                            state.user?.firstName &&
                            (state.user.role === 'Administrator' ||
                                state.user.role === 'SuperAdministrator') &&
                            (state.appId === 1 || state.appId === 3) ? (
                                <AdHocInvites />
                            ) : (
                                <Home />
                            )
                        }
                    />
                    <Route
                        path="business-segments"
                        element={
                            state.user &&
                            state.user?.firstName &&
                            state.user.role === 'SuperAdministrator' &&
                            (state.appId === 1 || state.appId === 3) ? (
                                <BusinessSegments />
                            ) : (
                                <Home />
                            )
                        }
                    />
                    <Route
                        path="user-activity"
                        element={
                            state.user &&
                            state.user?.firstName &&
                            (state.user.role === 'Administrator' ||
                                state.user.role === 'SuperAdministrator') &&
                            (state.appId === 1 || state.appId === 3) ? (
                                <UserActivity />
                            ) : (
                                <Home />
                            )
                        }
                    />

                    <Route
                        path="change-user-data"
                        element={
                            state.user &&
                            state.user?.firstName &&
                            (state.user.role === 'Administrator' ||
                                state.user.role === 'SuperAdministrator') &&
                            (state.appId === 1 || state.appId === 3) ? (
                                <ChangeUserData />
                            ) : (
                                <Home />
                            )
                        }
                    />

                    <Route
                        path="plants"
                        element={
                            state.appId === Number(process.env.REACT_APP_LINX_ID) ? (
                                <Plants />
                            ) : (
                                <Home />
                            )
                        }
                    />
                    <Route
                        path="contacts"
                        element={
                            state.appId === Number(process.env.REACT_APP_LINX_ID) ? (
                                <Contacts />
                            ) : (
                                <Home />
                            )
                        }
                    />
                    <Route
                        path="companies"
                        element={
                            state.appId === Number(process.env.REACT_APP_LINX_ID) ? (
                                <Companies />
                            ) : (
                                <Home />
                            )
                        }
                    />
                    <Route
                        path="business-events"
                        element={
                            state.appId === Number(process.env.REACT_APP_LINX_ID) ? (
                                <BusinessEvents />
                            ) : (
                                <Home />
                            )
                        }
                    />

                    <Route
                        path="elevate/admin/position"
                        element={state.user && state.user?.firstName ? <Position /> : <Home />}
                    />

                    <Route path="auth-error" element={<AuthError />} />
                </Routes>
                <ToastContainer />
            </Layout>
        </ThemeProvider>
    );
};

export default App;
